import React, { useEffect } from 'react';
import 'react-phone-input-2/lib/style.css';
import { useParams } from 'react-router-dom';
import { useQuote } from './hooks/use-quote';

type Props = {};

const QuotePage: React.FC<Props> = () => {
  const { hash } = useParams<any>();
  const { quote, loadingQuote } = useQuote(hash);

  useEffect(() => {
    if (!quote) return;
    window.location.href = quote.pdf;
  }, [quote]);

  return <></>;
};

export default QuotePage;
