import { withQuote } from '@/hoc/withQuote';
import colors from '@/styles/colors';
import { IAddress } from '@/types/address.model';
import { IField } from '@/types/field.model';
import { getWindowSize, handleError } from '@/utils';
import { Button, Form, Input, InputNumber, Select } from 'antd';
import Axios from 'axios';
import translatedCountries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import fr from 'i18n-iso-countries/langs/fr.json';
import nl from 'i18n-iso-countries/langs/nl.json';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { useParams } from 'react-router-dom';
import PlaceAutocomplete from '../app/components/PlaceAutocomplete';

translatedCountries.registerLocale(en);
translatedCountries.registerLocale(fr);
translatedCountries.registerLocale(nl);
interface ISubscribeData {
  id: string;
  company?: string;
  content?: string;
  title?: string;
  subtitle?: string;
  picturePath?: string;
  fields?: IField[];
  meta?: {
    [key: string]: {
      id?: string;
      name?: string;
    }[];
  };
  lang: string;
}

interface Props {
  quote?: {
    quote: string;
    author: string;
  };
}

const SubscribePage: React.FC<Props> = ({ quote }) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [data, setData] = useState<ISubscribeData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [backgroundImageLink, setBackgroundImageLink] = useState<string>('');
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [countries, setCountries] = useState<[string, string][]>();
  const [success, setSuccess] = useState<boolean>(false);
  const [alreadyRegistered, setAlreadyRegistered] = useState<boolean>(false);

  const fields = data?.fields?.filter((field: any) => field.display).sort((a, b) => a.position! - b.position!) || [];

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const random = Math.floor(Math.random() * 2 + 1);
    setBackgroundImageLink(`${process.env.PUBLIC_URL}/img/image-login-${random}.jpg`);
  }, []);

  useEffect(() => {
    let mounted = true;
    setData(null);
    if (quote?.quote && id) {
      setLoading(true);
      const cancelTokenSource = Axios.CancelToken.source();
      Axios.get(`${process.env.REACT_APP_API_URL}/subscription`, {
        params: {
          departmentId: id,
        },
        cancelToken: cancelTokenSource.token,
      })
        .then((response) => {
          if (mounted) {
            const countr = translatedCountries.getNames(response.data.lang.split('_')[0]);
            const sorted = Object.entries(countr).sort((a, b) => (a[1].toLowerCase() < b[1].toLowerCase() ? -1 : 1));
            setData({
              ...response.data,
              content: response.data.content ? response.data.content.replace(/<br\s*\/?>/gi, ' ') : '',
            });
            setCountries(sorted);
            form.resetFields();
            setLoading(false);
            setAlreadyRegistered(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (mounted) {
            form.resetFields();
          }
          if (!Axios.isCancel(error)) {
            console.log(error);
            if (error.response?.status === 403) {
              window.location.replace('https://shyfter.co');
            }
          }
        });
      return () => {
        mounted = false;
        cancelTokenSource.cancel();
      };
    }
  }, [id]);

  const onFinish = (values: any) => {
    setIsSaving(true);
    Axios.post(`${process.env.REACT_APP_API_URL}/subscription`, {
      ...values,
      departmentId: data?.id,
      fields: {
        ...values.fields,
        birthdate: moment(`${values.birthdate.day} ${values.birthdate.month} ${values.birthdate.year}`),
        niss: values.fields.niss ? String(values.fields.niss) : undefined,
      },
    })
      .then(() => {
        setIsSaving(false);
        setSuccess(true);
      })
      .catch((error) => {
        setIsSaving(false);
        if (error.status == 422) {
          setAlreadyRegistered(true);
        } else {
          handleError(error);
        }
      });
  };

  const onAddressChange = (address: IAddress) => {
    if (address) {
      form.setFieldsValue({
        fields: {
          ...address,
          zip: address.zip,
          city: address.city,
          country: address.country,
        },
      });
    }
  };

  const onShyfterStaff = () => {
    window.open('https://onelink.to/yjkpcj', '_blank');
  };

  const content = (
    <div
      className="right"
      style={{
        display: 'flex',
        flexDirection: windowSize.innerWidth <= 900 ? 'column' : 'unset',
        justifyContent: 'center',
        padding: 25,
        alignItems: windowSize.innerWidth <= 900 ? 'inherit' : 'unset',
      }}
    >
      {success ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 15 }}>
          <i className="icon-ok-circled" style={{ fontSize: 40, color: colors.green }} />
          <h2 style={{ fontWeight: 'bold', fontSize: 32 }}>{t('SUBSCRIBE.MODAL_SUBSCRIPTION_SENT.TITLE')}</h2>
          <p style={{ fontSize: 16 }}>{t('SUBSCRIBE.MODAL_SUBSCRIPTION_SENT.CONTENT')}</p>
        </div>
      ) : alreadyRegistered ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 15 }}>
          <i className="icon-info-circled" style={{ fontSize: 40, color: colors.orange }} />
          <h2 style={{ fontWeight: 'bold', fontSize: 32 }}>{t('SUBSCRIBE.ALREADY_REGISTERED_TITLE')}</h2>
          <p style={{ fontSize: 16, textAlign: 'center' }}>{t('SUBSCRIBE.ALREADY_REGISTERED_MESSAGE')}</p>
          <Button type="primary" size="large" onClick={onShyfterStaff}>
            Shyfter Staff
          </Button>
        </div>
      ) : (
        <Form form={form} size="large" layout="vertical" onFinish={onFinish}>
          <h1 dangerouslySetInnerHTML={{ __html: data?.title || '' }}></h1>
          <h2 dangerouslySetInnerHTML={{ __html: data?.subtitle || '' }}></h2>
          <p dangerouslySetInnerHTML={{ __html: data?.content || '' }}></p>
          <div className="fields">
            {data &&
              fields &&
              fields?.map((field) => {
                const { name, id, mandatory } = field;

                if (data.meta?.[id!]) {
                  return (
                    <Form.Item key={id} name={['fields', id!]} label={name} rules={[{ required: mandatory }]}>
                      <Select placeholder={name}>
                        {data.meta?.[id!].map((option) => (
                          <Select.Option value={option.id!} key={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  );
                } else if (id === 'country' || id === 'nationality_country_code') {
                  return (
                    <Form.Item key={id} name={['fields', id!]} label={name} rules={[{ required: mandatory }]}>
                      <Select
                        showSearch
                        placeholder={t('GLOBAL.COUNTRY')}
                        filterOption={(input, option) => {
                          const label = option!.label! as string;
                          return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                        allowClear
                      >
                        {countries?.map((key, i) => {
                          return (
                            <Select.Option value={key[0]} key={key[0]} label={key[1]}>
                              <div className="demo-option-label-item">{key[1]}</div>
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  );
                } else if (id == 'birthdate') {
                  return (
                    <Form.Item key={id} name={['fields', id!]} label={name} rules={[{ required: mandatory }]}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                        <Form.Item
                          name={['birthdate', 'day']}
                          rules={[{ required: mandatory }]}
                          style={{ flex: 1, marginBottom: 0 }}
                        >
                          <InputNumber
                            placeholder={t('GLOBAL.DAY')}
                            style={{ width: '100%' }}
                            inputMode="numeric"
                            min={1}
                            max={31}
                            maxLength={2}
                          />
                        </Form.Item>
                        <span>/</span>
                        <Form.Item
                          name={['birthdate', 'month']}
                          rules={[{ required: mandatory }]}
                          style={{ flex: 1, marginBottom: 0 }}
                        >
                          <InputNumber
                            placeholder={t('GLOBAL.MONTH')}
                            style={{ width: '100%' }}
                            inputMode="numeric"
                            min={1}
                            max={12}
                            maxLength={2}
                          />
                        </Form.Item>
                        <span>/</span>
                        <Form.Item
                          name={['birthdate', 'year']}
                          rules={[{ required: mandatory }]}
                          style={{ flex: 1, marginBottom: 0 }}
                        >
                          <InputNumber
                            placeholder={t('GLOBAL.YEAR')}
                            minLength={4}
                            maxLength={4}
                            style={{ width: '100%' }}
                            inputMode="numeric"
                          />
                        </Form.Item>
                      </div>
                    </Form.Item>
                  );
                } else if (id == 'phone') {
                  return (
                    <Form.Item key={id} name={['fields', id!]} label={name} rules={[{ required: mandatory }]}>
                      <PhoneInput
                        containerClass="ant-form-item-control-input-content"
                        inputClass="ant-input"
                        inputStyle={{ width: '100%', background: '#f7f8fb' }}
                      />
                    </Form.Item>
                  );
                } else if (id == 'address') {
                  return (
                    <Form.Item key={id} name={['fields', id!]} label={name} rules={[{ required: mandatory }]}>
                      <PlaceAutocomplete onAddressChange={onAddressChange} />
                    </Form.Item>
                  );
                } else {
                  return (
                    <Form.Item key={id} name={['fields', id!]} label={name} rules={[{ required: mandatory }]}>
                      <Input placeholder={name} />
                    </Form.Item>
                  );
                }
              })}
          </div>
          <Form.Item>
            <Button
              loading={isSaving}
              size="large"
              type="primary"
              htmlType="submit"
              className="login-form-button"
              block={windowSize.innerWidth <= 900}
            >
              {t('GLOBAL.SIGN_UP')}
            </Button>
          </Form.Item>
          <span className="accept_terms">{t('FORMS.ACCEPT_TERMS')}</span>
          <span className="generatedBy" style={{ paddingBottom: 30 }}>
            {t('SUBSCRIBE.GENERATE_BY_SHYFTER')}
          </span>
        </Form>
      )}
    </div>
  );

  return (
    <>
      {windowSize.innerWidth > 900 ? (
        <div className="dual-page">
          <div className="left" style={{ backgroundImage: `url("${backgroundImageLink}")` }}>
            {!!data?.picturePath && <img src={data?.picturePath} />}
            <h2>{quote?.quote}</h2>
            <i>{quote?.author}</i>
          </div>
          {content}
        </div>
      ) : (
        <>{content}</>
      )}
    </>
  );
};

const SubscribePageWithQuotes: React.FC = () => {
  const Component = withQuote(SubscribePage);
  return <Component />;
};

export default SubscribePageWithQuotes;
