import ModalSwitchToProAccount from '@/pages/app/components/ModalSwitchToProAccount';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IMarketplaceAppCategory } from '@/types/marketplace/marketplace-app-category.model';
import { IMarketplaceAppFields } from '@/types/marketplace/marketplace-app-fields.model';
import { IMarketplaceApp } from '@/types/marketplace/marketplace-app.model';
import { capitalize, isAdmin, isFree, isTrial } from '@/utils';
import { Button, Modal, message } from 'antd';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ModalPaidOption from '../ModalPaidOption';

interface Props {
  className?: string;
  activeApp: IMarketplaceApp | null;
  fieldDefinitions: IMarketplaceAppFields | null;
  category: IMarketplaceAppCategory;
  allDependenciesAreInstalled: boolean;
  appsDependingOnActive: IMarketplaceApp[];
  setActivePage: React.Dispatch<React.SetStateAction<'content' | 'auth' | 'success' | 'uninstalled'>>;
  getAppFieldDefinitions: () => void;
  getApps: () => void;
  setActiveApp: React.Dispatch<React.SetStateAction<IMarketplaceApp | null>>;
  getFeatures: () => void;
}

const Details: React.FC<Props> = ({
  className,
  activeApp,
  fieldDefinitions,
  category,
  allDependenciesAreInstalled,
  appsDependingOnActive,
  setActivePage,
  getAppFieldDefinitions,
  getApps,
  setActiveApp,
  getFeatures,
}) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, activeDepartmentId, loadingFeatures },
    dispatch,
  } = useContext(AppContext);
  const history = useHistory();
  const { pathname } = useLocation();
  const [loadingToggle, setLoadingToggle] = useState<boolean>(false);
  const [loadingSubscription, setLoadingSubscription] = useState<boolean>(false);
  const [modalPaidOptionVisible, setModalPaidOptionVisible] = useState<boolean>(false);
  const [modalSwitchProVisible, setModalSwitchProVisible] = useState<boolean>(false);

  const onWantToUninstall = () => {
    if (!activeApp) return;

    Modal.confirm({
      className: 'modal-danger',
      title: t('MARKETPLACE.MODAL_UNINSTALL_APP.TITLE', { app: activeApp.title }),
      icon: null,
      content: t('MARKETPLACE.MODAL_UNINSTALL_APP.CONTENT', { app: activeApp.title }),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.UNINSTALL'),
      okType: 'danger',
      width: 550,
      onOk: () => {
        onUninstall();
      },
    });
  };

  const onUninstall = () => {
    if (!activeApp) return;
    const impersonateToken = sessionStorage.getItem('impersonateToken');

    setLoadingToggle(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/marketplace/${activeApp.id}/uninstall`,
        { impersonateToken: impersonateToken && impersonateToken !== '' ? impersonateToken : undefined },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then(({ data }) => {
        getApps();
        getAppFieldDefinitions();
        setLoadingToggle(false);
        setActivePage('uninstalled');
      })
      .catch((error) => {
        const has_error = error && error.response && error.response.data && error.response.data.errors;
        message.error(
          has_error
            ? Object.values(error.response.data.errors).flat(1)[0]
            : t(fieldDefinitions?.enabled ? 'MARKETPLACE.DISABLE_APP_ERROR' : 'MARKETPLACE.ENABLE_APP_ERROR', {
                app: activeApp.name,
              }),
        );
        setLoadingToggle(false);
      });
  };

  const onWantToInstall = () => {
    if (!activeApp) return;
    setModalPaidOptionVisible(true);
  };

  const onInstall = () => {
    if (
      activeApp &&
      activeApp.fields.credentials == null &&
      activeApp.fields.settings == null &&
      activeApp.fields.support == null
    ) {
      setLoadingSubscription(true);
      const impersonateToken = sessionStorage.getItem('impersonateToken');

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v3/marketplace/${activeApp.id}/subscribe`,
          {
            credentials: activeApp.fields.credentials,
            settings: activeApp.fields.settings,
            support: activeApp.fields.support,
            impersonateToken: impersonateToken && impersonateToken !== '' ? impersonateToken : undefined,
          },
          {
            params: {
              departmentId: activeDepartmentId,
            },
          },
        )
        .then(({ data }) => {
          if (data.redirectUrl) {
            window.location = data.redirectUrl;
          } else {
            if (pathname.includes('-locked')) {
              setModalPaidOptionVisible(false);
              getFeatures();
            } else {
              setActivePage('success');
            }
          }
          getApps();
          setLoadingSubscription(false);
        })
        .catch((error) => {
          const has_error = error && error.response && error.response.data && error.response.data.errors;
          message.error(
            has_error
              ? Object.values(error.response.data.errors).flat(1)[0]
              : t('MARKETPLACE.SUBSCRIBE_ERROR', { app: activeApp.name }),
          );
          setLoadingSubscription(false);
        });
    } else {
      setActivePage('auth');
    }
  };

  const onWantToPurchase = () => {
    if (!activeApp) return;
    setModalPaidOptionVisible(true);
  };

  const onPurchase = () => {
    if (!activeApp) return;

    setLoadingSubscription(true);
    const impersonateToken = sessionStorage.getItem('impersonateToken');

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/marketplace/${activeApp.id}/subscribe`,
        { impersonateToken: impersonateToken && impersonateToken !== '' ? impersonateToken : undefined },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then(({ data }) => {
        if (data.redirectUrl) {
          window.location = data.redirectUrl;
        } else {
          if (pathname.includes('-locked')) {
            setModalPaidOptionVisible(false);
            getFeatures();
          } else {
            setActivePage('success');
          }
        }
        getApps();
        setLoadingSubscription(false);
      })
      .catch((error) => {
        const has_error = error && error.response && error.response.data && error.response.data.errors;
        message.error(
          has_error
            ? Object.values(error.response.data.errors).flat(1)[0]
            : t('MARKETPLACE.SUBSCRIBE_ERROR', { app: activeApp.name }),
        );
        setLoadingSubscription(false);
      });
  };

  const onModify = () => {
    setActivePage('auth');
  };

  const onSwitchToPro = () => {
    setModalSwitchProVisible(!modalSwitchProVisible);
  };

  return (
    <div className={className}>
      {(!activeApp?.installed || activeApp.category == 'TRAINING') && (
        <Button
          className="action-button"
          type="primary"
          size="large"
          onClick={
            isFree(activeDepartment!)
              ? onSwitchToPro
              : activeApp?.price_type == 'ONETIME'
              ? onWantToPurchase
              : activeApp?.price_type == 'MONTH'
              ? onWantToInstall
              : onInstall
          }
          loading={loadingFeatures || loadingSubscription}
          disabled={!isAdmin(activeDepartment!.role!) || !allDependenciesAreInstalled || isTrial(activeDepartment!)}
        >
          {isFree(activeDepartment!)
            ? t('GLOBAL.SWITCH_TO_PRO')
            : activeApp?.price_type == 'ONETIME'
            ? t('MARKETPLACE.PURCHASE')
            : t('GLOBAL.INSTALL')}
        </Button>
      )}
      {activeApp?.installed && (activeApp.fields.credentials != null || activeApp.fields.settings) && (
        <Button
          className="action-button"
          type="primary"
          size="large"
          onClick={onModify}
          disabled={!isAdmin(activeDepartment!.role!)}
        >
          {t('GLOBAL.MODIFY')}
        </Button>
      )}
      {activeApp?.installed && activeApp.category !== 'TRAINING' && (
        <Button
          className="action-button"
          type="primary"
          danger={true}
          size="large"
          onClick={onWantToUninstall}
          style={{
            marginTop:
              activeApp?.installed && (activeApp.fields.credentials != null || activeApp.fields.settings) ? 15 : 0,
          }}
          loading={loadingFeatures || loadingToggle}
          disabled={!isAdmin(activeDepartment!.role!) || appsDependingOnActive.length > 0}
        >
          {t('GLOBAL.UNINSTALL')}
        </Button>
      )}
      <div className="details">
        {activeApp?.installed && activeApp.category !== 'TRAINING' && (
          <span className="installed">{t('MARKETPLACE.INSTALLED')}</span>
        )}
        {activeApp?.price_type !== 'FREE' ? (
          <p className="price">
            {activeApp?.price}&euro;
            {activeApp?.price_type !== 'ONETIME' ? '/' : ' '}
            {t(`MARKETPLACE.PRICE_TYPES.${activeApp?.price_type}`).toLowerCase()}
          </p>
        ) : (
          <p className="price">{capitalize(t(`MARKETPLACE.PRICE_TYPES.FREE`))}</p>
        )}
        {activeApp?.price_type !== 'FREE' && (
          <span className="description">
            {t(`MARKETPLACE.${activeApp?.price_type == 'ONETIME' ? 'FIXED_AMOUNT_ONETIME' : 'FIXED_AMOUNT'}`)}
          </span>
        )}
        <div className="legals">
          <a href={activeApp?.legal_url} target="_blank">
            {t('MARKETPLACE.TERMS')}
          </a>
          <a href={activeApp?.privacy_url} target="_blank">
            {t('MARKETPLACE.PRIVACY_POLICY')}
          </a>
        </div>
      </div>
      <ModalPaidOption
        activeApp={activeApp!}
        onInstall={activeApp?.price_type == 'ONETIME' ? onPurchase : onInstall}
        visible={modalPaidOptionVisible}
        loading={loadingSubscription}
        setVisible={setModalPaidOptionVisible}
      />
      <ModalSwitchToProAccount visible={modalSwitchProVisible} setVisible={setModalSwitchProVisible} />
    </div>
  );
};

export default styled(Details)`
  flex: 1;
  border-left: 1px solid ${colors.grey};
  padding: 50px 25px;

  .action-button {
    width: 100%;
  }

  .details {
    margin-top: 15px;

    .installed {
      color: ${colors.green};
    }

    .price {
      font-weight: bold;
      margin: 0;
    }

    .description {
      font-size: 11px;
    }

    .legals {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      font-size: 11px;

      a {
        color: ${colors.grey};
        text-decoration: underline;
      }
    }
  }
`;
