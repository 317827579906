import { IAccountingQuote } from '@/types/super-admin/accounting/index.model';
import { handleError } from '@/utils';
import axios from 'axios';
import { useEffect, useState } from 'react';

export const useQuote = (hash: string) => {
  const [quote, setQuote] = useState<IAccountingQuote | null>(null);
  const [loadingQuote, setLoadingQuote] = useState<boolean>(false);

  useEffect(() => {
    getQuote();
  }, [hash]);

  const getQuote = () => {
    if (!hash) return;

    setLoadingQuote(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/quotes/${hash}`)
      .then(({ data }) => {
        setQuote(data.quote);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoadingQuote(false);
      });
  };

  return {
    quote,
    loadingQuote,
  };
};
