import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { getDatesBetweenDates, minutesToHoursAndOrMinutes } from '@/utils';
import { Spin } from 'antd';
import { isNaN } from 'lodash';
import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { InitialStateType } from '../../redux/store';

interface Props {
  className?: string;
  turnover: any;
  loadingStats: boolean;
}

const ScheduleStats: React.FC<Props> = ({ className, turnover, loadingStats }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, activeSection },
    dispatch,
  } = useContext(AppContext);
  const { days, usersMap, shiftsByUsers, startDate, showAbsences, isLoading, endDate } = useSelector(
    ({ days, usersMap, shiftsByUsers, startDate, showAbsences, isLoading, endDate }: InitialStateType) => ({
      days,
      usersMap,
      shiftsByUsers,
      startDate,
      endDate,
      isLoading,
      showAbsences,
    }),
  );

  const weekDays = getDatesBetweenDates(startDate, endDate);
  const productivityCoefficient = activeDepartment?.scheduleParams?.coefficient || 1;
  const productivityDefaultHours = activeDepartment?.scheduleParams?.default_hours || 0;

  let shyftTypes = [1];
  if (showAbsences) {
    shyftTypes = [1, 2];
  } else {
    shyftTypes = [1];
  }

  const shiftsByUsersWithoutFreeShifts = shiftsByUsers.map(({ user, shifts }) => ({
    user,
    shifts: shifts
      .filter(
        (shift) =>
          (!activeDepartment?.scheduleParams?.count_free_shift_in_head_count &&
            shift.shyftType &&
            shyftTypes.includes(shift.shyftType) &&
            shift.userRecordId !== null) ||
          (activeDepartment?.scheduleParams?.count_free_shift_in_head_count &&
            shift.shyftType &&
            shyftTypes.includes(shift.shyftType)) ||
          shift.userRecordId === null,
      )
      .filter((shift) => (shift.shyftType !== 2 ? true : shift.dayoff && shift.dayoff.paid == true ? true : false))
      .filter((shift) => shift.hide == false)
      .filter((shift) =>
        activeDepartment?.scheduleParams?.ghost_shifts && shift.section && activeSection
          ? shift.section.id == activeSection
          : true,
      ),
  }));

  const weekDates = getDatesBetweenDates(startDate, endDate);
  const valuesForSameDay = turnover
    ? weekDates
        .map((dateString) => {
          const timestamp = moment(dateString).unix();
          if (turnover.hasOwnProperty(timestamp)) {
            return turnover[timestamp] || null;
          } else {
            return null;
          }
        })
        .filter((value) => value !== null)
    : [];

  const totalHours = shiftsByUsersWithoutFreeShifts.reduce((result: any, item) => {
    item.shifts.forEach((shift) => {
      let shiftStart = moment.unix(shift.start!);
      let shiftEnd = moment.unix(shift.end!);
      const dayKey = shiftStart.format('YYYY-MM-DD');
      const turnoverOfDay = turnover ? turnover[moment(dayKey).startOf('day').unix()] : null;
      const unpaidPause = shift.pause?.unpaid;
      const paidPause = shift.pause?.paid;
      const durationInHours = shiftEnd.diff(shiftStart, 'hours', true);

      if (shiftStart.isSameOrAfter(startDate, 'day')) {
        let workTime = shift.clocking
          ? shift.clocking.reduce((value, clocking) => {
              if (!clocking.end) {
                return value + 0;
              }
              return value + (clocking.worktime ? clocking.worktime / 60 : 0 || 0);
            }, 0)
          : shift.shyftType == 1
          ? durationInHours - (unpaidPause ? unpaidPause / 3600 : 0) + (paidPause ? paidPause / 3600 : 0)
          : durationInHours - (unpaidPause ? unpaidPause / 3600 : 0) + (paidPause ? paidPause / 3600 : 0) * 60;

        if (turnoverOfDay && turnoverOfDay.type == 'real' && !shift.approved) {
          result[dayKey] = (result[dayKey] || 0) + 0;
        } else {
          result[dayKey] = (result[dayKey] || 0) + workTime;
        }
      }
    });

    weekDays.forEach((day, index) => {
      if (!result[day]) {
        result[day] = 0;
      }
    });

    return Object.keys(result)
      .sort()
      .reduce((sortedResult: any, key) => {
        sortedResult[key] = result[key];
        return sortedResult;
      }, {});
  }, {});

  const shiftsCost = shiftsByUsersWithoutFreeShifts.reduce((result: any, item) => {
    item.shifts.forEach((shift) => {
      const shiftMoment = moment.unix(shift.start!);

      // Check if the shift is in the current week
      if (shiftMoment.isSameOrAfter(startDate, 'day')) {
        const dayKey = shiftMoment.format('YYYY-MM-DD');
        const turnoverOfDay = turnover ? turnover[moment(dayKey).startOf('day').unix()] : null;
        const user = shift.userRecordId ? usersMap.get(shift.userRecordId!) : null;

        const price =
          shift.clocking && user && user.hourlyRate
            ? shift.clocking.reduce((result, item) => {
                if (!item.worktime) return result;
                if (!user.hourlyRate) return result;
                return result + (item.worktime / 60) * user.hourlyRate;
              }, 0)
            : shift.price;

        if (turnoverOfDay && turnoverOfDay.type == 'real' && !shift.approved) {
          result[dayKey] = (result[dayKey] || 0) + 0;
        } else {
          result[dayKey] = (result[dayKey] || 0) + price;
        }
      }
    });

    weekDays.forEach((day) => {
      if (!result[day]) {
        result[day] = 0;
      }
    });

    return Object.keys(result)
      .sort()
      .reduce((sortedResult: any, key) => {
        sortedResult[key] = result[key];
        return sortedResult;
      }, {});
  }, {});

  const averageCost = Object.keys(shiftsCost).reduce((result: any, dayKey) => {
    if (totalHours[dayKey]) {
      result[dayKey] = (shiftsCost[dayKey] / totalHours[dayKey]).toFixed(2);
    }
    weekDays.forEach((day) => {
      if (!result[day]) {
        result[day] = 0;
      }
    });

    return Object.keys(result)
      .sort()
      .reduce((sortedResult: any, key) => {
        sortedResult[key] = result[key];
        return sortedResult;
      }, {});
  }, {});

  const ratios = valuesForSameDay.map((data, index) => {
    if (data && data.value !== null && Object.values(shiftsCost)[index] !== null && data.value !== 0) {
      return Number(((Object.values(shiftsCost)[index] as any) / data.value) * 100).toFixed(2);
    } else {
      return null;
    }
  });

  const productivity = valuesForSameDay.map((data, index) => {
    const productivityCalculationType =
      activeDepartment?.scheduleParams?.productivity_calculation_type || 'HOURS_WORKED';
    if (data && data.value !== null) {
      if (productivityCalculationType === 'HOURS_WORKED') {
        return Number(
          Number(data.value / (totalHours[weekDates[index]] + productivityDefaultHours)) * productivityCoefficient,
        ).toFixed(2);
      } else {
        return Number(
          Number(data.value / (shiftsCost[weekDates[index]] + productivityDefaultHours)) * productivityCoefficient,
        ).toFixed(2);
      }
    } else {
      return null;
    }
  });

  const allEmpty =
    valuesForSameDay.every((turnover) => turnover.value == null) &&
    Object.values(totalHours).every((value) => value == 0) &&
    Object.values(shiftsCost).every((value) => value == 0);
  Object.values(averageCost).every((value) => value == 0);
  ratios.every((value) => value == null);
  productivity.every((value) => value == null);

  const hasTurnover = valuesForSameDay.some((data) => data.value !== null);

  const rows = [
    ...(hasTurnover
      ? [
          {
            id: 'turnover',
            title: t('SCHEDULE.STATS.DAY_TURNOVER'),
            data: valuesForSameDay,
            unit: '€',
          },
        ]
      : []),
    {
      id: 'hours',
      title: t('SCHEDULE.STATS.REAL_HOURS'),
      data: Object.values(totalHours),
      unit: '',
    },
    ...(Object.values(averageCost).some((value) => value != 0)
      ? [
          {
            id: 'average',
            title: t('SCHEDULE.STATS.AVERAGE_COST'),
            data: Object.values(averageCost),
            unit: `€/${t('GLOBAL.HOURS_SHORT')}`,
          },
        ]
      : []),
    ...(Object.values(shiftsCost).some((value) => value != 0)
      ? [
          {
            id: 'cost',
            title: t('SCHEDULE.STATS.REAL_COST'),
            data: Object.values(shiftsCost),
            unit: '€',
          },
        ]
      : []),
    ...(hasTurnover
      ? [
          {
            id: 'ratio',
            title: t('SCHEDULE.STATS.REAL_RATIO'),
            data: ratios,
            unit: '%',
          },
        ]
      : []),
    ...(hasTurnover
      ? [
          {
            id: 'productivity',
            title: t('SCHEDULE.STATS.PRODUCTIVITY'),
            data: productivity,
            unit: '',
          },
        ]
      : []),
  ];

  const totalTurnoverWeek = Number(
    valuesForSameDay
      .filter((value) => value !== null && value !== 'Infinity')
      .reduce((accumulator, currentValue) => accumulator + currentValue.value, 0),
  ).toFixed(2);
  const totalCostWeek = Number(
    Object.values(shiftsCost)
      .filter((value) => value !== null && value !== 'Infinity')
      .reduce((result: any, value: any) => result + value, 0),
  ).toFixed(2);
  const totalHoursWeek = Number(
    Object.values(totalHours)
      .filter((value) => value !== null && value !== 'Infinity')
      .reduce((result: any, value: any) => result + value, 0),
  ).toFixed(2);
  const totalProductivity = Number(
    Object.values(productivity)
      .filter((value) => value !== null && value !== 'Infinity')
      .reduce((result: any, value: any) => result + Number(value), 0),
  ).toFixed(2);

  const totalAverageCostWeek = Number(totalCostWeek) / Number(totalHoursWeek);
  const totalRatioWeek = Number((Number(totalCostWeek) / Number(totalTurnoverWeek)) * 100);
  const exceedingMinutes = shiftsByUsers
    .map(({ user, shifts }) => ({
      user,
      shifts: shifts
        .filter(
          (shift) =>
            (!activeDepartment?.scheduleParams?.count_free_shift_in_head_count &&
              shift.shyftType &&
              shyftTypes.includes(shift.shyftType) &&
              shift.userRecordId !== null) ||
            (activeDepartment?.scheduleParams?.count_free_shift_in_head_count &&
              shift.shyftType &&
              shyftTypes.includes(shift.shyftType)) ||
            shift.userRecordId === null,
        )
        .filter((shift) => shift.hide == false),
    }))
    .reduce((result, item) => {
      const userMaxWeekMinutes = item.user.maxWeekMinutes || 0;
      if (userMaxWeekMinutes == 0) return result;
      let totalUserMinutes = 0;

      item.shifts.forEach((shift) => {
        const shiftStart = moment.unix(shift.start!);
        const shiftEnd = moment.unix(shift.end!);
        const unpaidPause = shift.pause?.unpaid;
        const dayKey = shiftStart.format('YYYY-MM-DD');
        const turnoverOfDay = turnover ? turnover[moment(dayKey).startOf('day').unix()] : null;

        const durationInMinutes = shiftEnd.diff(shiftStart, 'minutes', true) - (unpaidPause || 0) / 60;

        if (turnoverOfDay && turnoverOfDay.type == 'real' && !shift.approved) {
          totalUserMinutes += 0;
        } else {
          totalUserMinutes += durationInMinutes;
        }
      });

      if (totalUserMinutes > 0) {
        result += totalUserMinutes - userMaxWeekMinutes;
      }

      return result;
    }, 0);

  return (
    <div className={className}>
      {loadingStats || isLoading ? (
        <Spin className="loading" spinning={loadingStats} />
      ) : (
        <>
          <div className="top" style={{ padding: allEmpty ? '10px 0' : '20px 0' }}>
            <div className="left">
              <h4 className="title">{t('SCHEDULE.STATS.WEEK_RATIO')}</h4>
            </div>
            <div className="right">
              {hasTurnover && (
                <div className="indicator">
                  <div className="title">{t('SCHEDULE.STATS.WEEK_TURNOVER')}</div>
                  {!allEmpty && (
                    <div className="value">
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: activeDepartment?.currency || 'EUR',
                      })
                        .format(Number(totalTurnoverWeek))
                        .slice(Number(totalTurnoverWeek) < 0 ? 2 : 1)
                        .replace(/[,\.]/g, (match) => (match === ',' ? '.' : ','))
                        .replace(/(?:\.(?:00)+|,(?:00)+)$/, '') || '-'}
                      €
                    </div>
                  )}
                </div>
              )}
              <div className="indicator">
                <div className="title">{t('SCHEDULE.STATS.COST')}</div>
                {!allEmpty && (
                  <div className="value">
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: activeDepartment?.currency || 'EUR',
                    })
                      .format(Number(totalCostWeek))
                      .slice(Number(totalCostWeek) < 0 ? 2 : 1)
                      .replace(/[,\.]/g, (match) => (match === ',' ? '.' : ','))
                      .replace(/(?:\.(?:00)+|,(?:00)+)$/, '') || '-'}
                    €
                  </div>
                )}
              </div>
              <div className="indicator">
                <div className="title">{t('SCHEDULE.STATS.WORKED_HOURS')}</div>
                {!allEmpty && (
                  <div className="value">{minutesToHoursAndOrMinutes(Number(totalHoursWeek) * 60) || '-'}</div>
                )}
              </div>
              <div className="indicator">
                <div className="title">{t('SCHEDULE.STATS.AVERAGE_COST')}</div>
                {!allEmpty && (
                  <div className="value">
                    {isNaN(Number(totalAverageCostWeek))
                      ? '-'
                      : `${new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: activeDepartment?.currency || 'EUR',
                        })
                          .format(Number(totalAverageCostWeek))
                          .slice(Number(totalAverageCostWeek) < 0 ? 2 : 1)
                          .replace(/[,\.]/g, (match) => (match === ',' ? '.' : ','))
                          .replace(/(?:\.(?:00)+|,(?:00)+)$/, '')}€/h`}
                  </div>
                )}
              </div>
              {hasTurnover && (
                <div className="indicator">
                  <div className="title">{t('SCHEDULE.STATS.AVERAGE_PRODUCTIVITY') || '-'}</div>
                  {!allEmpty && (
                    <div className="value">
                      {isNaN(
                        Number(
                          Number(totalProductivity) /
                            Object.values(productivity).filter((value) => value !== null).length,
                        ),
                      )
                        ? '-'
                        : Number(
                            Number(totalProductivity) /
                              Object.values(productivity).filter((value) => value !== null).length,
                          ).toFixed(2)}
                    </div>
                  )}
                </div>
              )}
              {hasTurnover && (
                <div className="indicator">
                  <div className="title">{t('SCHEDULE.STATS.RATIO')}</div>
                  {!allEmpty && (
                    <div
                      className={`value ${
                        totalRatioWeek == 0 ? 'neutral' : totalRatioWeek > 0 ? 'positive' : 'negative'
                      }`}
                    >
                      {totalRatioWeek.toFixed(2)}%
                    </div>
                  )}
                </div>
              )}
              <div className="indicator">
                <div className="title">{t('SCHEDULE.STATS.OVERBOOKING')}</div>
                {!allEmpty && (
                  <div
                    className={`value ${
                      exceedingMinutes == 0 ? 'neutral' : exceedingMinutes > 0 ? 'negative' : 'positive'
                    }`}
                  >
                    {minutesToHoursAndOrMinutes(Number(exceedingMinutes))}
                    {!isNaN(Number(totalAverageCostWeek * (exceedingMinutes / 60))) && (
                      <span>
                        (
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: activeDepartment?.currency || 'EUR',
                        })
                          .format(Number(totalAverageCostWeek * (exceedingMinutes / 60)))
                          .slice(Number(totalAverageCostWeek * (exceedingMinutes / 60)) < 0 ? 2 : 1)
                          .replace(/[,\.]/g, (match) => (match === ',' ? '.' : ','))
                          .replace(/(?:\.(?:00)+|,(?:00)+)$/, '')}
                        €)
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div className="indicator">
                <div className="title"></div>
                <div className="value"></div>
              </div>
              {!hasTurnover && (
                <>
                  <div className="indicator">
                    <div className="title"></div>
                    <div className="value"></div>
                  </div>
                  <div className="indicator">
                    <div className="title"></div>
                    <div className="value"></div>
                  </div>
                </>
              )}
            </div>
          </div>
          {allEmpty ? (
            <div className="bottom">
              <span className="no-data">{t('SCHEDULE.STATS.NO_DATA')}</span>
            </div>
          ) : (
            <div className="bottom">
              <div className="left">
                {rows.map((row) => (
                  <span className="title">{row.title}</span>
                ))}
              </div>
              <div className="right">
                {days.map((day, index) => (
                  <div className="day">
                    {rows.map((row) => {
                      let value;
                      value = Number(row.data[index]).toFixed(2);
                      if (row.id == 'turnover' && row.data[index]) {
                        value = Number(row.data[index].value).toFixed(2);
                      }
                      if (row.id == 'hours') {
                        value = minutesToHoursAndOrMinutes(row.data[index] * 60);
                      }
                      if (row.id == 'cost' || row.id == 'average') {
                        value = new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: activeDepartment?.currency || 'EUR',
                        })
                          .format(Number(row.data[index]))
                          .slice(Number(row.data[index]) < 0 ? 2 : 1)
                          .replace(/[,\.]/g, (match) => (match === ',' ? '.' : ','))
                          .replace(/(?:\.(?:00)+|,(?:00)+)$/, '');
                      }
                      if (row.data[index] == null) {
                        value = null;
                      }
                      if (!row.data[index]) return <div>-</div>;
                      return (
                        <div
                          className="field"
                          style={{
                            color: row.data[index].type
                              ? row.data[index].type == 'real'
                                ? colors.green
                                : colors.blueLightPastel
                              : '#000',
                          }}
                        >
                          {value == 'Infinity' || isNaN(value) ? '-' : value}
                          {row.data[index] !== null ? row.unit : '-'}
                        </div>
                      );
                    })}
                  </div>
                ))}
                {!!activeDepartment?.scheduleParams?.sidebarCounters && <div className="counters"></div>}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default styled(ScheduleStats)`
  display: flex;
  flex-direction: column;

  .loading {
    margin-bottom: 10px;
  }

  .top {
    background-color: #fafafa;
    display: flex;
    align-items: center;

    .left {
      width: 210px;

      .title {
        font-weight: bold;
        margin: 0;
        padding: 0 10px;
      }
    }

    .right {
      flex: 1;
      display: flex;
      align-items: center;

      .indicator {
        text-align: center;
        flex: 1;

        .title  {
          font-weight: bold;
        }

        .value {
          font-size: 16px;
          font-weight: bold;

          &.neutral {
            color: '#000';
          }
          &.positive {
            color: ${colors.green};
          }
          &.negative {
            color: ${colors.red};
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    padding: 10px 0;
    justify-content: center;

    .no-data {
      color: ${colors.grey};
      font-weight: bold;
      font-size: 16px;
    }

    .left {
      width: 210px;
      display: flex;
      flex-direction: column;
      padding: 0 10px;
      border-right: 1px solid #efefef;
      gap: 10px;
    }

    .right {
      flex: 1;
      display: flex;

      .day {
        flex: 1;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-right: 1px solid #efefef;
      }

      .counters {
        flex: 1;
      }
    }
  }
`;
