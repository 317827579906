import AppContext from '@/pages/app/context';
import { IMarketplaceAppCategory } from '@/types/marketplace/marketplace-app-category.model';
import { IMarketplaceAppFields } from '@/types/marketplace/marketplace-app-fields.model';
import { IMarketplaceApp } from '@/types/marketplace/marketplace-app.model';
import { Button, DatePicker, Form, Input, InputNumber, Select, Switch, Tooltip, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  activeApp: IMarketplaceApp | null;
  fieldDefinitions: IMarketplaceAppFields | null;
  category: IMarketplaceAppCategory;
  setActivePage: React.Dispatch<React.SetStateAction<'content' | 'auth' | 'success' | 'uninstalled'>>;
  getApps: () => void;
}

const Auth: React.FC<Props> = ({ className, activeApp, fieldDefinitions, category, setActivePage, getApps }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (!fieldDefinitions) return;

    form.resetFields();

    form.setFieldsValue(fieldDefinitions);
  }, [fieldDefinitions]);

  const onFinish = (values: any) => {
    if (!activeApp) return;

    const settings = values.settings ? Object.values(values.settings).filter((setting) => setting != undefined) : null;

    setLoading(true);
    if (activeApp.installed) {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/v3/marketplace/${activeApp.id}/subscribe`,
          {
            settings: settings ? (settings.length == 0 ? null : formatSettings(values.settings)) : null,
          },
          {
            params: {
              departmentId: activeDepartmentId,
            },
          },
        )
        .then(({ data }) => {
          setActivePage('success');
          setLoading(false);
        })
        .catch((error) => {
          const has_error = error && error.response && error.response.data && error.response.data.errors;
          message.error(
            has_error
              ? Object.values(error.response.data.errors).flat(1)[0]
              : t('MARKETPLACE.UPDATE_SUBRIPTION_ERROR', { app: activeApp.name }),
          );
          setLoading(false);
        })
        .finally(() => {
          getApps();
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v3/marketplace/${activeApp.id}/subscribe`,
          {
            credentials: values.credentials,
            settings: settings ? (settings.length == 0 ? null : formatSettings(values.settings)) : null,
            support: values.support,
          },
          {
            params: {
              departmentId: activeDepartmentId,
            },
          },
        )
        .then(({ data }) => {
          if (data.redirectUrl) {
            window.location = data.redirectUrl;
          } else {
            setActivePage('success');
          }
          setLoading(false);
        })
        .catch((error) => {
          const errors = error && error.response && error.response.data && error.response.data.errors;
          if (!errors) {
            message.error(t('MARKETPLACE.SUBSCRIBE_ERROR', { app: activeApp.name }));
            return;
          }
          setErrors(errors);
          setLoading(false);
        })
        .finally(() => {
          getApps();
        });
    }
  };

  const formatSettings = (settings: Record<string, any>) => {
    const formattedSettings: Record<string, any> = {};

    Object.entries(settings).forEach(([key, value]) => {
      // Check if the value is a string and a valid ISO 8601 date
      if (typeof value === 'string' && moment(value, moment.ISO_8601, true).isValid()) {
        formattedSettings[key] = moment(value).format('YYYY-MM-DD');
      } else {
        // Keep other values as they are
        formattedSettings[key] = value;
      }
    });

    return formattedSettings;
  };

  return (
    <div className={className}>
      {!activeApp?.installed && <p>{t('MARKETPLACE.AUTH_TITLE', { app: activeApp?.name })}</p>}
      <Form layout="vertical" size="large" form={form} onFinish={onFinish}>
        {!activeApp?.installed &&
          activeApp?.fields.credentials?.map((field) => (
            <Form.Item name={['credentials', field.name]} label={field.label}>
              {field.type == 'text' ? (
                <Input placeholder={field.description} />
              ) : field.type == 'password' ? (
                <Input placeholder={field.description} type="password" />
              ) : null}
            </Form.Item>
          ))}
        {!activeApp?.installed &&
          activeApp?.fields.support?.map((field) => (
            <Form.Item name={['support', field.name]} label={field.label}>
              {field.type == 'text' ? (
                <Input placeholder={field.description} />
              ) : field.type == 'password' ? (
                <Input placeholder={field.description} type="password" />
              ) : null}
            </Form.Item>
          ))}
        {activeApp?.fields.settings && activeApp.fields.settings.length > 0 && (
          <div style={{ marginBottom: 25, marginTop: activeApp.installed ? 0 : 25 }}>
            <h2>{t('GLOBAL.SETTINGS')}</h2>
            {activeApp?.fields.settings
              ?.filter((setting) => (!activeApp.installed ? !setting.hideOnInstall : true))
              .map((setting) => (
                <Form.Item
                  name={['settings', setting.name]}
                  label={
                    setting.description && setting.description != '' ? (
                      <Tooltip overlay={setting.description}>{setting.label}</Tooltip>
                    ) : (
                      setting.label
                    )
                  }
                  valuePropName={setting.type == 'checkbox' ? 'checked' : undefined}
                  className={`settings_field ${setting.type}`}
                  style={{ margin: 0, marginTop: 10 }}
                  validateStatus={errors[`settings.${setting.name}`] ? 'error' : undefined}
                  help={errors[`settings.${setting.name}`] ? errors[`settings.${setting.name}`][0] : undefined}
                >
                  {setting.type == 'text' ? (
                    <Input placeholder={setting.description} />
                  ) : setting.type == 'checkbox' ? (
                    <Switch />
                  ) : setting.type == 'int' ? (
                    <InputNumber style={{ width: 100 }} precision={0} min={0} />
                  ) : setting.type == 'float' ? (
                    <InputNumber style={{ width: 100 }} precision={2} step={0.01} min={0} />
                  ) : setting.type == 'date' ? (
                    <DatePicker style={{ width: 150 }} />
                  ) : setting.type == 'select' ? (
                    <Select
                      placeholder={setting.description}
                      mode={setting.multiple ? 'multiple' : undefined}
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option!.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                      }
                    >
                      {setting.options?.map((option) => (
                        <Select.Option key={option.key} value={option.id}>
                          {option.label || option.title}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : null}
                </Form.Item>
              ))}
          </div>
        )}
        <Form.Item>
          <Button htmlType="submit" type="primary" size="large" loading={loading}>
            {t('GLOBAL.CONFIRM')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default styled(Auth)`
  width: 75%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0;

  .checkbox,
  .date,
  .int,
  .float {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .ant-form-item-label {
      flex: 1;
    }

    .ant-form-item-control {
      flex: 0;
    }

    .ant-form-item-label,
    label {
      padding: 0;
    }
  }

  .settings_field {
    .ant-form-item-control {
      flex: 1;
      align-items: flex-end;
    }
  }
`;
