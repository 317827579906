import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IResource } from '@/types/resource.model';
import { IUser } from '@/types/user.model';
import { Button, Checkbox, Input, message } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
  departmentId?: string;
  resource: IResource | undefined;
}

const Access: React.FC<Props> = ({ className, departmentId, resource }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const { id, attributeId } = useParams() as any;
  const {
    state: { users, resources },
    dispatch,
  } = useContext(AppContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [userList, setUserList] = useState<IUser[]>(users);
  const [filteredUserList, setFilteredUserList] = useState<IUser[]>([]);
  const [toggleAllUsers, setToggleAllUsers] = useState<boolean>(false);
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const attribute = resource?.attributes?.find((attr) => attr.id === attributeId);

  // Apply attribute logic on component mount
  useEffect(() => {
    if (attribute?.users === null) {
      setToggleAllUsers(true);
      setUserList(users.map((user) => ({ ...user, active: true })));
    } else if (Array.isArray(attribute?.users)) {
      setToggleAllUsers(false);
      setUserList(
        users.map((user) => ({
          ...user,
          active: attribute.users.includes(user.recordId!),
        })),
      );
    }
  }, [attribute, users]);

  // Filter user list when `userList` or `search` changes
  useEffect(() => {
    setFilteredUserList(userList.filter((user) => user.displayName?.toLowerCase().includes(search.toLowerCase())));
  }, [userList, search]);

  const onCheck = (recordId: string) => {
    setFormHasChanged(true);
    setUserList((prev) => prev.map((user) => (user.recordId === recordId ? { ...user, active: !user.active } : user)));
  };

  const toggleCheckAll = () => {
    setFormHasChanged(true);
    const allChecked = !toggleAllUsers;
    setToggleAllUsers(allChecked);
    setUserList((prev) => prev.map((user) => ({ ...user, active: allChecked })));
  };

  const onSubmit = () => {
    setIsSaving(true);
    const users = userList.filter((user) => user.active).map((user) => user.recordId);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/resources/${id}/${attributeId}/users`,
        {
          users,
        },
        {
          params: { departmentId },
        },
      )
      .then(() => {
        setFormHasChanged(false);
        dispatch({
          type: 'SET_RESOURCES',
          payload: resources.map((resource) =>
            resource.id == id
              ? {
                  ...resource,
                  attributes: resource.attributes?.map((attribute) =>
                    attribute.id == attributeId ? { ...attribute, users: users as any } : attribute,
                  ),
                }
              : resource,
          ),
        });
      })
      .catch(() => message.error(t('GLOBAL.MESSAGE_SAVING_ERROR')))
      .finally(() => setIsSaving(false));
  };

  const onReset = () => {
    setFormHasChanged(false);
    if (attribute?.users === null) {
      setUserList(users.map((user) => ({ ...user, active: true })));
    } else if (Array.isArray(attribute?.users)) {
      setUserList(
        users.map((user) => ({
          ...user,
          active: attribute.users.includes(user.recordId!),
        })),
      );
    }
  };

  return (
    <div className={className}>
      <div className="counter">
        <span className="number">{userList.filter((user) => user.active).length}</span>
        <span className="description">{t('GLOBAL.USER(S)')}</span>
        <Button style={{ marginTop: '10px' }} type={toggleAllUsers ? 'default' : 'primary'} onClick={toggleCheckAll}>
          {toggleAllUsers ? t('GLOBAL.DESELECT_ALL_USERS') : t('GLOBAL.SELECT_ALL_USERS')}
        </Button>
      </div>
      <Input
        placeholder={t('GLOBAL.SEARCH')}
        onChange={(e) => setSearch(e.target.value)}
        style={{ marginBottom: 10 }}
      />
      <ul className="user-list">
        {filteredUserList.map((user) => (
          <li key={user.recordId}>
            <Checkbox checked={user.active || false} onClick={() => onCheck(user.recordId!)}>
              {user.displayName}
            </Checkbox>
          </li>
        ))}
      </ul>
      <div className={`actions ${formHasChanged ? 'active' : ''}`}>
        <Button size="large" type="primary" onClick={onSubmit} loading={isSaving} style={{ marginRight: '10px' }}>
          {t('GLOBAL.SAVE')}
        </Button>
        <Button size="large" type="default" onClick={onReset}>
          {t('GLOBAL.CANCEL')}
        </Button>
      </div>
    </div>
  );
};

const NotesStyled = styled(Access)`
  .counter {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-direction: column;
    margin-bottom: 20px;
    color: ${colors.green};

    .number {
      font-size: 3em;
      line-height: 0.9em;
      font-weight: bold;
    }
  }

  .user-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 5px 0;
    }
  }

  .actions {
    position: fixed;
    width: calc(100vw - 740px);
    background: white;
    bottom: -80px;
    margin: 0;
    margin-left: -20px;
    display: flex;
    box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.15);
    padding: 10px;
    border-radius: 3px 3px 0 0;
    transition: bottom 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    &.active {
      transition-timing-function: ease-in;
      bottom: 0px;
    }
  }
`;

export default NotesStyled;
