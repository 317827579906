import DrawerHrRule from '@/pages/app/components/drawers/HrRule';
import AppContext from '@/pages/app/context';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { IHrRule } from '@/types/hr-rule.model';
import { ISettingsListElement } from '@/types/settings/list-element.model';
import { getWindowSize, isCountry, isNullOrUndefined } from '@/utils';
import { Button, Form, Input, message, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SettingsForm from '../../components/SettingsForm';
import HrRulesTable from './components/HrRulesTable';
import SpecialCode from './components/SpecialCode';

interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
}

const HrRules: React.FC<Props> = ({ className, currentSettings, updateCurrentSettings }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, hrPartners, loadingHrPartners },
  } = useContext(AppContext);
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [currentCode, setCurrentCode] = useState<Code | null>();
  const [customHrCodes, setCustomHrCodes] = useState<any[]>([]);
  const [customHrCodesEdited, setCustomHrCodesEdited] = useState<any[]>(customHrCodes);
  const [customHrDescription, setCustomHrDescription] = useState<string>('');
  const [customHrCode, setCustomHrCode] = useState<string>('');
  const [start, setStart] = useState<moment.Moment>(moment().startOf('day'));
  const [end, setEnd] = useState<number | null>();
  const [code, setCode] = useState<string>();
  const [drawerHrRuleVisible, setDrawerHrRuleVisible] = useState<boolean>(false);
  const [rules, setRules] = useState<IHrRule[]>([]);
  const [activeRule, setActiveRule] = useState<IHrRule | null>(null);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (currentSettings?.hr?.custom_hr_codes) {
      setCustomHrCodes(currentSettings?.hr?.custom_hr_codes as any);
      setCustomHrCodesEdited(currentSettings?.hr?.custom_hr_codes as any);
    }
    setFetching(true);
    let mounted = true;
    onReset();
    getRules();
    return () => {
      mounted = false;
      setFetching(false);
    };
  }, [currentSettings]);

  useEffect(() => {
    onReset();
  }, []);

  const getRules = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/hr/rules`, {
        params: {
          departmentId: activeDepartment?.id,
        },
      })
      .then(({ data }) => {
        setRules(data);
      })
      .catch((err) => {
        message.error(Object.values(err.response.data.errors).flat(1)[0]);
      });
  };

  const onReset = () => {
    setFormHasChanged(false);
    form.resetFields();
    let fieldsValue: any = {};
    let found;
    const codes = hrPartners.map((hr: any) => hr.codes);
    for (let i = 0; i < codes.length; i++) {
      const arr = codes[i];
      found = arr.find((el: any) => el.id === currentSettings?.hr?.holiday_special_code?.toString());
    }

    if (currentSettings) {
      const _extrahours_endtime = currentSettings?.general?.extrahours_endtime;
      const _extrahours_starttime = currentSettings?.general?.extrahours_starttime;
      const _nightshift_start = currentSettings?.general?.nightshift_start;
      const _nightshift_end = currentSettings?.general?.nightshift_end;
      const _holiday_special_code = currentSettings?.hr?.holiday_special_code;
      const _nightshift_code_id = currentSettings?.general?.nightshift_code_id;

      fieldsValue = {
        ...fieldsValue,
        ...currentSettings,
        general: {
          ...currentSettings.general,
          extrahours_starttime: _extrahours_starttime ? moment(`1970-01-01T${_extrahours_starttime}`) : undefined,
          extrahours_endtime: _extrahours_endtime ? moment(`1970-01-01T${_extrahours_endtime}`) : undefined,
          nightshift_start: _nightshift_start ? moment(`1970-01-01T${_nightshift_start}`).format('HH:mm') : undefined,
          nightshift_end: _nightshift_end ? moment(`1970-01-01T${_nightshift_end}`).format('HH:mm') : undefined,
          nightshift_code_id: _nightshift_code_id?.toString(),
        },
        hr: {
          ...currentSettings.hr,
          holiday_special_code: _holiday_special_code?.toString(),
          custom_hr_codes: currentSettings?.hr?.custom_hr_codes,
        },
      };
    }

    form.setFieldsValue(fieldsValue);
    if (currentSettings?.hr?.custom_hr_codes) {
      setCustomHrCodes(currentSettings?.hr?.custom_hr_codes as any);
      setCustomHrCodesEdited(currentSettings?.hr?.custom_hr_codes as any);
    }
  };

  const onStartChange = ({ hour, minute }: { hour: number | null; minute: number | null }) => {
    const startTimestamp = moment(start)
      .set('hour', hour || 0)
      .set('minute', minute || 0)
      .unix();
    form.setFieldsValue({
      start: startTimestamp,
    });
    setEnd(hour ? startTimestamp : null);
    const endDate = form.getFieldValue('end');
    if (endDate) {
      const endMoment = moment.unix(endDate);
      let endTimestamp = moment(start).set('hour', endMoment.hours()).set('minute', endMoment.minutes()).unix();
      if (endTimestamp < startTimestamp) {
        endTimestamp = moment.unix(endTimestamp).add(1, 'day').unix();
      }
      form.setFieldsValue({
        end: endTimestamp,
      });
    }
  };

  const onEndChange = ({ hour, minute }: { hour: number | null; minute: number | null }) => {
    let endValue = null;
    const startDate = form.getFieldValue('start');
    if (!isNullOrUndefined(hour)) {
      let endTimestamp = moment(start)
        .set('hour', hour || 0)
        .set('minute', minute || 0)
        .unix();
      if (startDate && endTimestamp < startDate) {
        endTimestamp = moment.unix(endTimestamp).add(1, 'day').unix();
      }
      endValue = endTimestamp;
    }

    form.setFieldsValue({
      end: endValue,
    });
  };

  const onCodeChange = (value: string) => {
    setCode(value);
  };

  const onAdd = () => {
    setCustomHrCodes([...customHrCodes, { description: customHrDescription, code: customHrCode }]);
    setCustomHrCodesEdited([...customHrCodes, { description: customHrDescription, code: customHrCode }]);
    setCustomHrDescription('');
    setCustomHrCode('');
  };

  const onCustomHrDescriptionChange = (e: any) => {
    setFormHasChanged(true);
    setCustomHrDescription(e.target.value);
  };

  const onCustomHrCodeChange = (value: any) => {
    setFormHasChanged(true);
    setCustomHrCode(value);
  };

  const onRemove = (code: any) => {
    setFormHasChanged(true);
    setCustomHrCodes(customHrCodes.filter((c) => c.code !== code.code));
    setCustomHrCodesEdited(customHrCodesEdited.filter((c) => c.code !== code.code));
  };

  const onCreateNewRule = () => {
    setDrawerHrRuleVisible(true);
  };

  const elements = [
    {
      title: t('SETTINGS.HR.HR_RULES.TITLES.SPECIAL_HR_CODES'),
      visible: true,
    },
    {
      title: t('SETTINGS.HR.HR_RULES.TITLES.HR_RULES'),
      visible: isCountry(activeDepartment!, ['BE']),
    },
  ];

  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('SETTINGS.HR.HR_RULES.SPECIAL_CODES.LABEL'),
          description: t('SETTINGS.HR.HR_RULES.SPECIAL_CODES.DESCRIPTION'),
          style: { margin: 0 },
          element: (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: windowSize.innerWidth > 900 ? 'row' : 'column',
                  alignItems: windowSize.innerWidth > 900 ? 'center' : 'flex-start',
                  marginTop: 15,
                  gap: windowSize.innerWidth > 900 ? 0 : 10,
                }}
              >
                <Input
                  placeholder={t('GLOBAL.DESCRIPTION')}
                  value={customHrDescription}
                  onChange={onCustomHrDescriptionChange}
                  style={{ marginRight: 15 }}
                />
                <Select
                  placeholder={t('SETTINGS.HR.SPECIAL_CODES.HR_PARTNER_CODE')}
                  onChange={onCustomHrCodeChange}
                  value={customHrCode}
                  optionFilterProp="children"
                  filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  allowClear
                  getPopupContainer={(trigger) => trigger}
                  showSearch
                >
                  {hrPartners.map((hr) =>
                    hr.codes.map((code: any) => (
                      <Select.Option key={`code_${code.id}`} value={`${code.id}`}>
                        {`${code.name} (${code.code})`}
                      </Select.Option>
                    )),
                  )}
                </Select>
                <Button
                  type="primary"
                  style={{
                    marginLeft: windowSize.innerWidth > 900 ? 15 : 0,
                    padding: 5,
                    width: windowSize.innerWidth <= 900 ? '100%' : 'auto',
                  }}
                  onClick={onAdd}
                >
                  <i className="icon-plus"></i>
                </Button>
              </div>
              {customHrCodes.map((code) => (
                <div key={`custom_code_${code.description}`} style={{ display: 'flex', alignItems: 'center' }}>
                  <SpecialCode
                    code={code}
                    hrPartners={hrPartners}
                    customHrCodesEdited={customHrCodesEdited}
                    setCustomHrCodesEdited={setCustomHrCodesEdited}
                    onRemove={() => onRemove(code)}
                    setFormHasChanged={setFormHasChanged}
                  />
                </div>
              ))}
            </>
          ),
          elementBelow: true,
          show: hrPartners.length > 0,
        },
      ],
    ],
    [
      [
        {
          label: t('SETTINGS.HR.HR_RULES.BREAK_DEDUCTION.LABEL'),
          description: t('SETTINGS.HR.HR_RULES.BREAK_DEDUCTION.DESCRIPTION'),
          style: { margin: 0 },
          element: (
            <Form.Item name={['general', 'hr_pause_deduction']}>
              <Select style={{ margin: 0 }}>
                <Select.Option value="start">{t('SETTINGS.ACCOUNT.HR_RULES.ON_START_HOUR')}</Select.Option>
                <Select.Option value="end">{t('SETTINGS.ACCOUNT.HR_RULES.ON_END_HOUR')}</Select.Option>
              </Select>
            </Form.Item>
          ),
          show: hrPartners.length > 0,
        },
      ],
      [
        {
          label: t('SETTINGS.HR.HR_RULES.HR_RULES.LABEL'),
          description: t('SETTINGS.HR.HR_RULES.HR_RULES.DESCRIPTION'),
          style: { margin: 0 },
          element: (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 25, marginTop: 15 }}>
              <div style={{ alignSelf: 'flex-end' }}>
                <Button type="primary" onClick={onCreateNewRule}>
                  {t('HR.RULES.ADD_RULE')}
                </Button>
              </div>
              <HrRulesTable
                hrPartners={hrPartners}
                rules={rules}
                setRules={setRules}
                setActiveRule={setActiveRule}
                getRules={getRules}
              />
            </div>
          ),
          elementBelow: true,
          show: hrPartners.length > 0,
        },
      ],
    ],
  ];

  const onFinish = (values: any) => {
    setLoading(true);
    const _extrahours_endtime = values?.general?.extrahours_endtime;
    const _extrahours_starttime = values?.general?.extrahours_starttime;

    const newValues = {
      general: {
        ...currentSettings?.general,
        ...values?.general,
        extrahours_starttime: _extrahours_starttime ? _extrahours_starttime.format('HH:mm:ss') : undefined,
        extrahours_endtime: _extrahours_endtime ? _extrahours_endtime.format('HH:mm:ss') : undefined,
        nightshift_code_id: code === undefined ? null : code ? code : currentCode?.id,
      },
      hr: {
        ...values?.hr,
        custom_hr_codes:
          customHrCodesEdited.length > 0
            ? customHrDescription && customHrCode
              ? [...customHrCodesEdited, { code: customHrCode, description: customHrDescription }]
              : customHrCodesEdited
            : customHrDescription && customHrCode
            ? [
                {
                  code: customHrCode,
                  description: customHrDescription,
                },
              ]
            : [],
      },
    };

    updateCurrentSettings(newValues)
      .then(() => {
        setLoading(false);
        setCurrentCode(null);
        setCustomHrDescription('');
        setCustomHrCode('');
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.HR.HR_RULES.TITLE')}</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
      <DrawerHrRule
        visible={drawerHrRuleVisible}
        setDrawerVisible={setDrawerHrRuleVisible}
        setActiveRule={setActiveRule}
        activeRule={activeRule}
        rules={rules}
        setRules={setRules}
        hrPartners={hrPartners}
      />
    </div>
  );
};

interface Code {
  id: string;
  name: string;
}

export default HrRules;
