import AppContext from '@/pages/app/context';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { FEATURES } from '@/types/features.model';
import { ISettingsListElement } from '@/types/settings/list-element.model';
import { handleError, isClockingAccount, isFeatureEnabled, isTrial } from '@/utils';
import { Form, Switch } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SettingsForm from '../../components/SettingsForm';

interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings | null;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
}

const HrStaff: React.FC<Props> = ({ className, currentSettings, updateCurrentSettings }) => {
  const { t } = useTranslation();
  const {
    state: { features, activeDepartment },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    onReset();
  }, [currentSettings]);

  const elements = [
    {
      title: t('GLOBAL.GENERAL'),
      visible: true,
    },
  ];
  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('SETTINGS.HR.STAFF.HOMEWORKING.LABEL'),
          description: t('SETTINGS.HR.STAFF.HOMEWORKING.DESCRIPTION'),
          name: ['general', 'homeworking'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.general?.homeworking} />,
          show: !isTrial(activeDepartment!),
        },
        {
          label: t('SETTINGS.HR.STAFF.DISPLAY_EXTRA_HOURS.LABEL'),
          description: t('SETTINGS.HR.STAFF.DISPLAY_EXTRA_HOURS.DESCRIPTION'),
          name: ['general', 'show_extra_hours'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.general?.show_extra_hours} />,
          show: !isClockingAccount(features),
        },
        {
          label: t('SETTINGS.HR.STAFF.ALLOW_DAYOFF_REQUESTS.LABEL'),
          description: t('SETTINGS.HR.STAFF.ALLOW_DAYOFF_REQUESTS.DESCRIPTION'),
          name: ['general', 'vacation_requests'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.general?.vacation_requests} />,
          show: isFeatureEnabled(features, FEATURES.LEAVE_MANAGEMENT),
        },
      ],
    ],
  ];

  const onReset = () => {
    setFormHasChanged(false);
    form.resetFields();
    form.setFieldsValue({ ...currentSettings });
  };

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    updateCurrentSettings({ ...values })
      .then(() => {})
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.HR.STAFF.TITLE')}</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
    </div>
  );
};

export default styled(HrStaff)``;
