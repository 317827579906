import * as Sentry from '@sentry/react';
import axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/nl';
import React, { Suspense, useEffect, useReducer } from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ReactQueryProvider from './ReactQueryProvider';
import { setToken } from './actions';
import ErrorBoundary from './components/ErrorBoundary';
import SSpin from './components/SSpin';
import ScrollToTop from './components/ScrollToTop';
import AuthContext, { initialState } from './context';
import './i18n';
import i18n from './i18n';
import './index.css';
import reducer from './reducer';
import * as serviceWorker from './serviceWorker';

moment.tz.setDefault('Atlantic/Reykjavik');

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    'app/reports/timesheets/default',
  ],
  release: process.env.REACT_APP_SENTRY_RELEASE,
  beforeSend(event, hint) {
    if (event.exception?.values) {
      for (const exception of event.exception.values) {
        if (exception.stacktrace && exception.stacktrace.frames) {
          for (const frame of exception.stacktrace.frames) {
            if (frame.filename && (frame.filename.includes('gtag/destination') || frame.filename.includes('gtag/js'))) {
              return null;
            }
          }
        }
      }
    }
    return event;
  },
});

const tagManagerArgs = {
  gtmId: 'GTM-MWHZB62',
  dataLayerName: 'PageDataLayer',
};

const Root: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue = {
    state,
    dispatch,
  };

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        if (response.headers['x-git-sha']) {
          dispatch({
            type: 'SET_GIT_SHA',
            payload: response.headers['x-git-sha'],
          });
        }
        return response;
      },
      async (error) => {
        if (error.response?.status === 401) {
          setToken(dispatch, null);
        }
        if (error.response?.status === 402) {
          dispatch({
            type: 'SET_TRIAL_END_DATE',
            payload: 0,
          });
          dispatch({
            type: 'SET_BLOCKED',
            payload: false,
          });
        }
        return Promise.reject(error);
      },
    );

    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      setToken(dispatch, accessToken);
    }

    const impersonateToken = sessionStorage.getItem('impersonateToken');
    const impersonateDepartmentId = sessionStorage.getItem('impersonateDepartmentId');

    if (impersonateToken) {
      setToken(dispatch, impersonateToken, true, impersonateDepartmentId);
    } else {
      TagManager.initialize(tagManagerArgs);
    }

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return (
    <AuthContext.Provider value={contextValue}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <ScrollToTop />
          <ReactQueryProvider>
            <App />
          </ReactQueryProvider>
        </BrowserRouter>
      </I18nextProvider>
    </AuthContext.Provider>
  );
};

ReactDOM.render(
  //<React.StrictMode>
  <ErrorBoundary>
    <Suspense fallback={<SSpin spinning={true} style={{ width: '100%', height: '100vh' }} />}>
      <Root />
    </Suspense>
  </ErrorBoundary>,
  //</React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
