import { setToken } from '@/actions';
import AuthContext from '@/context';
import { withQuote } from '@/hoc/withQuote';
import colors from '@/styles/colors';
import { Button, Form, Input, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
  quote?: {
    quote: string;
    author: string;
  };
}

const LoginPage: React.FC<Props> = ({ className, quote }) => {
  const location = useLocation();
  const history = useHistory();
  const { state: authContextState, dispatch: authContextDispatch } = useContext(AuthContext);
  const { accessToken } = authContextState;
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [isLoading, setIsLoading] = useState(false);
  const { from } = (location.state as any) || { from: { pathname: '/app' } };
  const [form] = useForm();
  const [backgroundImageLink, setBackgroundImageLink] = useState<string>('');
  const [showTrialMessage, setShowTrialMessage] = useState(false);
  const params = new URLSearchParams(location.search);
  const trial = params.get('trial');

  useEffect(() => {
    if (trial && trial == 'true') {
      setShowTrialMessage(true);
    }
  }, [trial]);

  useEffect(() => {
    const random = Math.floor(Math.random() * 2 + 1);
    setBackgroundImageLink(`${process.env.PUBLIC_URL}/img/image-login-${random}.jpg`);
  }, []);

  useEffect(() => {
    if (location.search) {
      const accessTokenParam = new URLSearchParams(location.search).get('accessToken');
      setToken(authContextDispatch, accessTokenParam);
    }
  }, [location.search]);

  useEffect(() => {
    if (accessToken && !isLoading) {
      history.replace(from);
    }
    // eslint-disable-next-line
  }, [accessToken, isLoading]);

  const onFinish = (values: any) => {
    const { email, password } = values;
    setIsLoading(true);
    Axios.post(`${process.env.REACT_APP_API_URL}/login`, {
      email,
      password,
    })
      .then(({ data }) => {
        const { token } = data;
        setIsLoading(false);
        setToken(authContextDispatch, token);
      })
      .catch((error) => {
        setIsLoading(false);
        setToken(authContextDispatch, null);
        message.error(error.response?.data?.message);
        console.error(error);
      });
  };

  return (
    <div className={`dual-page ${className}`}>
      <div className="left" style={{ backgroundImage: `url("${backgroundImageLink}")` }}>
        <h2>{quote?.quote}</h2>
        <i>{quote?.author}</i>
      </div>
      <div className="right">
        <Form form={form} size="large" layout="vertical" onFinish={onFinish} requiredMark={false}>
          <img
            className="shyfter-logo"
            src={process.env.PUBLIC_URL + '/shyfter-logo.svg'}
            alt="Shyfter"
            style={{ margin: 0, width: 150 }}
          />
          {showTrialMessage && (
            <div style={{ marginTop: 50, fontWeight: 'bold' }}>
              <p style={{ color: '#000', fontSize: 24, margin: 0, marginBottom: 12 }}>{t('LOGIN.TRIAL_TITLE')}</p>
              <p style={{ color: '#000', fontWeight: 'normal', fontSize: 14 }}>{t('LOGIN.TRIAL_MESSAGE')}</p>
            </div>
          )}
          <div className="fields">
            <Form.Item
              label={t('GLOBAL.EMAIL')}
              name="email"
              rules={[{ required: true, message: t('FORMS.EMAIL_ERROR') }]}
            >
              <Input size="large" prefix={<i className="icon-user"></i>} placeholder={t('GLOBAL.EMAIL')} />
            </Form.Item>
            <Link to="/forgot-password" className="forgot-password">
              {t('LOGIN.FORGOT_PASSWORD_LINK')}
            </Link>
            <Form.Item
              label={t('FORMS.PASSWORD')}
              name="password"
              rules={[{ required: true, message: t('FORMS.PASSWORD_ERROR') }]}
              style={{ marginBottom: 5 }}
            >
              <Input
                size="large"
                prefix={<i className="icon-lock"></i>}
                type="password"
                placeholder={t('FORMS.PASSWORD')}
              />
            </Form.Item>
          </div>
          <Form.Item>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={isLoading}
              style={{ marginTop: 12 }}
            >
              {t('LOGIN.LOGIN_BUTTON')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const LoginPageStyled = styled(LoginPage)`
  .right {
    form {
      .fields {
        .forgot-password {
          position: absolute;
          right: 0;
          color: ${colors.green};
          text-decoration: underline;
          z-index: 2;
        }
        label.ant-checkbox-wrapper {
          font-size: 12px;
          font-weight: normal;
        }
      }
    }
  }
`;

const LoginPageWithQuotes: React.FC = () => {
  const Component = withQuote(LoginPageStyled);
  return <Component />;
};

export default LoginPageWithQuotes;
