import { setToken } from '@/actions';
import AuthContext from '@/context';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { FEATURES } from '@/types/features.model';
import { isFeatureEnabled, SUPER_ADMIN_ACCOUNTING_ALLOWED_EMAILS } from '@/utils';
import { Avatar, Badge, Drawer, Menu, Select } from 'antd';
import { Crisp } from 'crisp-sdk-web';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { store as dashboardStore } from '../../dashboard/redux/store';
import { store as manageStore } from '../../hours/manage/redux/store';

interface Props {
  className?: string;
  visible: boolean;
  toggleDrawer: () => void;
}

const submenusKeys = ['sub1', 'sub2', 'sub3', 'sub4', 'sub5', 'sub6', 'sub7'];

const HeaderSmallDrawer: React.FC<Props> = ({ className, visible, toggleDrawer }) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const {
    dispatch: authContextDispatch,
    state: { userDetails, impersonate },
  } = useContext(AuthContext);
  const { state, dispatch: appContextDispatch } = useContext(AppContext);
  const { activeDepartment, departments, sections, resources, badges, features } = state;

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const hours_path = `${path}/hours`;
  const team_path = `${path}/team`;
  const reports_path = `${path}/reports`;
  const documents_path = `${path}/documents`;
  const super_admin_path = `${path}/super-admin`;
  const settings_path = `${path}/settings`;

  const [map, setMap] = useState<any>({
    sub0: [`${path}/dashboard`],
    sub01: [`${path}/clockings`],
    sub1: [
      `${hours_path}/manage`,
      `${hours_path}/shift-modification-requests`,
      `${hours_path}/clocking-validation`,
      `${hours_path}/shyfts-market`,
      `${hours_path}/shift-approvals`,
      `${hours_path}/tasks-forecasts`,
      `${hours_path}/vacation-requests`,
      `${hours_path}/messages`,
      `${hours_path}/shirt-notifications`,
    ],
    sub2: [
      `${team_path}/collaborators/all`,
      `${team_path}/pending-collaborators`,
      `${team_path}/pending-registrations`,
      `${team_path}/settings/attributes/manage`,
    ],
    sub3: [
      `${reports_path}/timesheets`,
      `${reports_path}/activity-reports`,
      `${reports_path}/leave-reports`,
      `${reports_path}/hr-sheets`,
      `${reports_path}/hr-sheet-exports`,
      `${reports_path}/service-sheets`,
      `${reports_path}/pay-reports`,
    ],
    sub4: [
      `${documents_path}/contracts/all`,
      `${documents_path}/contracts/new`,
      `${documents_path}/contracts/templates`,
      `${documents_path}/contracts/signatures`,
      `${documents_path}/documents/all`,
      `${documents_path}/documents/new`,
      `${documents_path}/documents/templates`,
      `${documents_path}/dimona/statement`,
      `${documents_path}/dimona/audit`,
    ],
    sub5: [
      `${super_admin_path}/accounts/all`,
      `${super_admin_path}/pricing-simulator`,
      `${super_admin_path}/change-email`,
      `${super_admin_path}/users-import`,
      `${super_admin_path}/users-batch`,
      `${super_admin_path}/create-invoice`,
      `${super_admin_path}/impersonate`,
      `${super_admin_path}/billing-recap`,
      `${super_admin_path}/accounting`,
    ],
    sub6: ['sub6-1', 'sub6-2', 'sub-6-3'],
    sub7: [`${settings_path}/bills`, `${path}/logs`, `${settings_path}/profile`],
  });

  useEffect(() => {
    const new_map = map;
    const new_sub2: string[] = new_map['sub2'];
    resources.map((resource) => new_sub2.push(`${team_path}/resources/${resource.id}`));
    new_map['sub2'] = new_sub2;

    const new_sub4: string[] = new_map['sub4'];
    activeDepartment?.weeklyReports?.map((wr) => new_sub4.unshift(`${documents_path}/weekly-reports/${wr.id}`));
    new_map['sub4'] = new_sub4;

    setMap(new_map);
  }, []);

  useEffect(() => {
    let found: string[] = ['sub1'];
    Object.values(map).forEach((item: any) => {
      item.forEach((el: string, i: number) => {
        if (el === location.pathname || location.pathname.startsWith(el)) {
          found = item;
        }
      });
    });
    setSelectedKeys(found.filter((value) => value == location.pathname || location.pathname.startsWith(value)));
    setOpenKeys([Object.keys(map).find((key: any) => map[key] == found) as string]);
  }, [location.pathname, map]);

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key: string) => openKeys.indexOf(key) === -1);
    if (submenusKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onClick = () => {
    toggleDrawer();
  };

  const openWhatsNew = () => {
    switch (i18n.language) {
      case 'fr':
        window.open('https://shyfter.notion.site/Quoi-de-neuf-sur-Shyfter-3d85b835f4444550b582a10e8325f585', '_blank');
        break;
      case 'nl':
        window.open(
          'https://shyfter.notion.site/Wat-is-er-nieuw-op-Shyfter-8b8df2f8403b447fab339f7afe0b39fc',
          '_blank',
        );
        break;
      default:
        window.open('https://shyfter.notion.site/What-s-new-on-Shyfter-fdce3ee77547498b87419094b984ca35', '_blank');
        break;
    }
  };

  const openSupport = () => {
    switch (i18n.language) {
      case 'fr':
        window.open('https://help.shyfter.co/fr/', '_blank');
        break;
      case 'nl':
        window.open('https://help.shyfter.co/nl/', '_blank');
        break;
      case 'es':
        window.open('https://help.shyfter.co/es/', '_blank');
        break;
      default:
        window.open('https://help.shyfter.co', '_blank');
        break;
    }
  };

  const openChat = () => {
    // TagManager.dataLayer({
    //   dataLayer: {
    //     event: 'launchchat',
    //   },
    //   dataLayerName: 'PageDataLayer',
    // });
    if (process.env.REACT_APP_CRISP_WEBSITE_ID) {
      Crisp.chat.show();
      Crisp.chat.open();
    }
  };

  const logout = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (impersonate && accessToken) {
      sessionStorage.removeItem('impersonateToken');
      history.push('/app');
      setToken(authContextDispatch, accessToken);
    } else {
      history.push('/app');
      localStorage.clear();
      manageStore.dispatch({
        type: 'RESET',
      });
      dashboardStore.dispatch({
        type: 'RESET',
      });
      authContextDispatch({
        type: 'RESET',
      });
      appContextDispatch({
        type: 'RESET',
      });
    }
  };

  const changeDepartment = (department_id: string) => {
    if (department_id && activeDepartment?.id !== department_id) {
      const department = departments?.find((x) => x.id === department_id);
      if (department) {
        appContextDispatch({
          type: 'SET_ACTIVE_DEPARTMENT',
          payload: department?.id,
        });
        authContextDispatch({
          type: 'SET_TRIAL_END_DATE',
          payload: department?.trialEndDate || null,
        });
        authContextDispatch({
          type: 'SET_BLOCKED',
          payload: department?.accountType === 'BLOCKED' ? true : false,
        });
        history.push('/app/dashboard');
      }
    }
  };

  return (
    <Drawer
      width={'75%'}
      className={className}
      placement="right"
      closable={false}
      visible={visible}
      onClose={toggleDrawer}
    >
      {activeDepartment?.partner?.icon ? (
        <img className="logo-icon" src={activeDepartment?.partner?.icon} alt={activeDepartment?.partner?.name} />
      ) : (
        <img className="logo-icon" src={process.env.PUBLIC_URL + '/shyfter-logo-min.svg'} alt="Shyfter" />
      )}
      <Menu
        onClick={onClick}
        openKeys={openKeys}
        mode="inline"
        onOpenChange={onOpenChange}
        style={{ flex: 1 }}
        selectedKeys={selectedKeys}
      >
        {isFeatureEnabled(features, FEATURES.SECTIONS) && !!sections?.length && (
          <Select
            placeholder="Aucune section"
            style={{ paddingLeft: 18, width: '100%', marginBottom: 15, marginTop: 15 }}
            allowClear
          >
            {!activeDepartment?.scheduleParams?.force_section && sections?.length > 1 && (
              <Select.Option value="">{t('FORMS.ALL_SECTIONS')}</Select.Option>
            )}
            {sections.map((section) => (
              <Select.Option key={section.id} value={section.id!}>
                {section.name}
              </Select.Option>
            ))}
          </Select>
        )}
        <NavLink to="/app/dashboard">
          <Menu.Item
            key="/app/dashboard"
            style={{ fontSize: 14, paddingLeft: 18, textTransform: 'uppercase', color: colors.green }}
          >
            <i className="icon-layers" />
            <span className="submenu-title">{t('DASHBOARD.TITLE')}</span>
          </Menu.Item>
        </NavLink>
        {isFeatureEnabled(features, FEATURES.CLOCKING) && activeDepartment?.access?.clockings && (
          <NavLink to={`/app/clockings/${moment().format('YYYY-MM-DD')}`}>
            <Menu.Item
              key={`/app/clockings/${moment().format('YYYY-MM-DD')}`}
              style={{ fontSize: 14, paddingLeft: 18, textTransform: 'uppercase', color: colors.green }}
            >
              <i className="icon-stopwatch" />
              <span className="submenu-title">{t('MENU.CLOCKINGS.TITLE')}</span>
            </Menu.Item>
          </NavLink>
        )}
        {activeDepartment?.access?.shifts && (
          <Menu.SubMenu
            title={
              <>
                <i className="icon-calendar-alt" />
                <span className="submenu-title">{t('MENU.SCHEDULE.SCHEDULE.TITLE')}</span>
              </>
            }
            key={'sub1'}
          >
            <Menu.ItemGroup title="Schedules">
              <Menu.Divider />
              <Menu.Item key="/app/hours/manage">
                <NavLink to="/app/hours/manage">{t('MENU.SCHEDULE.SCHEDULE.SCHEDULE_MANAGEMENT')}</NavLink>{' '}
                <Badge count={badges?.schedule?.approvals} />
              </Menu.Item>

              {activeDepartment?.access?.requests && (
                <Menu.Item key="/app/hours/shift-modification-requests">
                  <NavLink to="/app/hours/shift-modification-requests">
                    {t('SCHEDULE.CHANGE_REQUESTS.TITLE')} <Badge count={badges?.schedule?.requests} />
                  </NavLink>
                </Menu.Item>
              )}

              {!!isFeatureEnabled(features, FEATURES.CLOCKING) &&
                activeDepartment.access?.clockings &&
                !activeDepartment?.clockinParams?.approveFreeclockin && (
                  <Menu.Item key="/app/clockings/clocking-validation">
                    <NavLink to="/app/clockings/clocking-validation">
                      {t('SCHEDULE.CLOCKING_VERIFICATION.TITLE')}{' '}
                      <Badge count={badges?.schedule?.clockinVerification} />
                    </NavLink>
                  </Menu.Item>
                )}

              {!!isFeatureEnabled(features, FEATURES.FREESHIFTS) && !!activeDepartment?.scheduleParams?.shyftsMarket && (
                <Menu.Item key="/app/hours/shyfts-market">
                  <NavLink to="/app/hours/shyfts-market">
                    Shifts Market <Badge count={badges?.schedule?.shyftsMarket} />
                  </NavLink>
                </Menu.Item>
              )}

              {!!activeDepartment?.scheduleParams?.validateShifts && (
                <Menu.Item key="/app/hours/shift-approvals">
                  <NavLink to="/app/hours/shift-approvals">
                    {t('SCHEDULE.SHIFT_APPROVALS.TITLE')} <Badge count={badges?.schedule?.validation} />
                  </NavLink>
                </Menu.Item>
              )}

              {activeDepartment?.id === '1445' && (
                <Menu.Item key="/app/hours/tasks-forecasts">
                  <NavLink to="/app/hours/tasks-forecasts">Tasks forecasts</NavLink>
                </Menu.Item>
              )}

              <Menu.Item key="/app/hours/vacation-requests">
                <NavLink to="/app/hours/vacation-requests">
                  {t('VACATION_REQUESTS.TITLE')} <Badge count={badges?.schedule?.vacation} />
                </NavLink>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup title="Alerts & Messaging">
              <Menu.Divider />
              <Menu.Item key="/app/hours/messages">
                <NavLink to="/app/hours/messages">{t('GLOBAL.MESSAGES')}</NavLink>
              </Menu.Item>
              {!!isFeatureEnabled(features, FEATURES.FREESHIFTS) && (
                <Menu.Item key="/app/hours/shift-notifications">
                  <NavLink to="/app/hours/shift-notifications">{t('FREE_SHIFT_NOTIFICATIONS.TITLE')}</NavLink>
                </Menu.Item>
              )}
            </Menu.ItemGroup>
          </Menu.SubMenu>
        )}
        {['ADMIN', 'PLANNING', 'HR'].includes(activeDepartment?.role || '') && (
          <Menu.SubMenu
            title={
              <>
                <i className="icon-user" />
                <span className="submenu-title">{t('MENU.TEAM.TITLE')}</span>
              </>
            }
            key={'sub2'}
          >
            <Menu.ItemGroup title={t('MENU.TEAM.STAFF_LIST')}>
              <Menu.Divider />
              <Menu.Item key="/app/team/collaborators/all">
                <NavLink to="/app/team/collaborators/all">{t('GLOBAL.USERS')}</NavLink>
              </Menu.Item>
              {!!activeDepartment?.userSubscription && (
                <Menu.Item key="/app/team/pending-collaborators">
                  <NavLink to="/app/team/pending-collaborators">
                    {t('USERS.USER_VALIDATION.TITLE')} <Badge count={badges?.team?.pendingUsers} />
                  </NavLink>
                </Menu.Item>
              )}
              <Menu.Item key="/app/team/pending-registrations">
                <NavLink
                  to="/app/team/pending-registrations"
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  {t('USERS.PENDING_REGISTRATIONS.TITLE')}
                  <Badge count={badges?.users?.registrations} />
                </NavLink>
              </Menu.Item>
            </Menu.ItemGroup>
            {isFeatureEnabled(features, FEATURES.ATTRIBUTES) && !!resources?.length && (
              <Menu.ItemGroup title="Resources">
                <Menu.Item key="/app/settings/attributes/manage">
                  <NavLink to="/app/settings/attributes/manage">
                    {t('RESOURCES.PENDING_RESOURCES')} <Badge count={badges?.team?.pendingResources} />
                  </NavLink>
                </Menu.Item>
              </Menu.ItemGroup>
            )}
          </Menu.SubMenu>
        )}
        {(activeDepartment?.access?.reports || activeDepartment?.access?.timesheets) && (
          <Menu.SubMenu
            title={
              <>
                <i className="icon-chart-bar" />
                <span className="submenu-title">{t('MENU.REPORTS.TITLE')}</span>
              </>
            }
            key={'sub3'}
          >
            <Menu.ItemGroup title={t('MENU.REPORTS.TITLE')}>
              <Menu.Divider />

              {activeDepartment?.access?.reports && (
                <>
                  <Menu.Item key="/app/reports/timesheets">
                    <NavLink to="/app/reports/timesheets">{t('REPORTS.CONTROL_OF_SERVICES.TITLE')}</NavLink>
                  </Menu.Item>
                  <Menu.Item key="/app/reports/activity-reports">
                    <NavLink to="/app/reports/activity-reports">{t('REPORTS.ACTIVITY_REPORT.TITLE')}</NavLink>
                  </Menu.Item>
                </>
              )}

              {activeDepartment?.access?.reports && (
                <Menu.Item key="/app/reports/leave-reports">
                  <NavLink to="/app/reports/leave-reports">{t('REPORTS.LEAVE_REPORTS.TITLE')}</NavLink>
                </Menu.Item>
              )}

              {activeDepartment?.access?.timesheets && (
                <>
                  <Menu.Item key="/app/reports/hr-sheets">
                    <NavLink to="/app/reports/hr-sheets">{t('REPORTS.HR_TIMESHEETS.TITLE')}</NavLink>
                  </Menu.Item>

                  <Menu.Item key="/app/reports/hr-sheet-exports">
                    <NavLink to="/app/reports/hr-sheet-exports">{t('REPORTS.EXPORT_HR_TIMESHEETS.TITLE')}</NavLink>
                  </Menu.Item>
                </>
              )}

              {activeDepartment?.access?.reports && (
                <Menu.Item key="/app/reports/service-sheets">
                  <NavLink to="/app/reports/service-sheets">{t('REPORTS.SIGNUP_SHEETS.TITLE')}</NavLink>
                </Menu.Item>
              )}

              {activeDepartment?.access?.reports && activeDepartment.params?.payReport && (
                <Menu.Item key="/app/reports/pay-reports">
                  <NavLink to="/app/reports/pay-reports">{t('REPORTS.PAYROLL_REPORT.TITLE')}</NavLink>
                </Menu.Item>
              )}
            </Menu.ItemGroup>
          </Menu.SubMenu>
        )}
        <Menu.SubMenu
          title={
            <>
              <i className="icon-doc-text-inv" />
              <span className="submenu-title">{t('MENU.DOCUMENTS.TITLE')}</span>
            </>
          }
          key={'sub4'}
        >
          {!!activeDepartment?.weeklyReports && (
            <Menu.ItemGroup title={t('MENU.DOCUMENTS.PRINTING_SCHEDULES.TITLE')}>
              <Menu.Divider />
              {activeDepartment?.weeklyReports &&
                activeDepartment.weeklyReports.map((wr, i) => (
                  <Menu.Item key={`/app/documents/weekly-reports/${wr.id}`}>
                    <NavLink to={`/app/documents/weekly-reports/${wr.id}`}>{wr.name}</NavLink>
                  </Menu.Item>
                ))}
            </Menu.ItemGroup>
          )}
          {((isFeatureEnabled(features, FEATURES.CONTRACTS) && activeDepartment?.access?.documents) ||
            !!activeDepartment?.weeklyReports?.length ||
            (isFeatureEnabled(features, FEATURES.DIMONA) &&
              activeDepartment?.params?.dimona &&
              activeDepartment?.access?.dimona)) && (
            <>
              <Menu.ItemGroup title={t('GLOBAL.CONTRACTS')}>
                <Menu.Divider />
                <Menu.Item key="/app/documents/contracts">
                  <NavLink to="/app/documents/contracts">{t('DOCUMENTS.CONTRACTS.TITLE')}</NavLink>
                </Menu.Item>
                <Menu.Item key="/app/documents/contracts/new">
                  <NavLink to="/app/documents/contracts/new">{t('CONTRACTS.NEW_CONTRACT')}</NavLink>
                </Menu.Item>
                <Menu.Item key="/app/documents/contracts/templates">
                  <NavLink to="/app/documents/contracts/templates">{t('CONTRACTS.TEMPLATES.TITLE')}</NavLink>
                </Menu.Item>
                <Menu.Item key="/app/documents/contracts/signatures">
                  <NavLink to="/app/documents/contracts/signatures">{t('DOCUMENTS.SIGNATURES.TITLE')}</NavLink>
                </Menu.Item>
              </Menu.ItemGroup>
              <Menu.ItemGroup title="Documents">
                <Menu.Divider />
                <Menu.Item key="/app/documents/documents">
                  <NavLink to="/app/documents/documents">{t('DOCUMENTS.DOCUMENTS.TITLE')}</NavLink>
                </Menu.Item>
                <Menu.Item key="/app/documents/documents/new">
                  <NavLink to="/app/documents/documents/new">{t('DOCUMENTS.DOCUMENTS.DOCUMENT.NEW_DOCUMENT')}</NavLink>
                </Menu.Item>
                <Menu.Item key="/app/documents/documents/templates">
                  <NavLink to="/app/documents/documents/templates">{t('DOCUMENTS.TEMPLATES.TITLE')}</NavLink>
                </Menu.Item>
              </Menu.ItemGroup>
            </>
          )}
          {isFeatureEnabled(features, FEATURES.DIMONA) &&
            activeDepartment?.params?.dimona &&
            activeDepartment?.access?.dimona && (
              <Menu.ItemGroup title="Dimona management">
                <Menu.Divider />
                <Menu.Item key="/app/documents/dimona/statement">
                  <NavLink to="/app/documents/dimona/statement">{t('DIMONA.TITLE')}</NavLink>
                </Menu.Item>
                <Menu.Item key="/app/documents/dimona/audit">
                  <NavLink to="/app/documents/dimona/audit">{t('DIMONA.AUDIT.TITLE')}</NavLink>
                </Menu.Item>
              </Menu.ItemGroup>
            )}
        </Menu.SubMenu>
        {['SUPER-ADMIN', 'SSA'].includes(userDetails?.role || '') && (
          <Menu.SubMenu
            title={
              <>
                <i className="icon-lock" />
                <span className="submenu-title">Super-admin</span>
              </>
            }
            key={'sub5'}
          >
            <Menu.Item key="/app/super-admin/accounts/all">
              <NavLink to="/app/super-admin/accounts/all">{t('SUPER_ADMIN.TITLES.ACCOUNTS_LIST')}</NavLink>
            </Menu.Item>
            <Menu.Item key="/app/super-admin/pricing-simulator">
              <NavLink to="/app/super-admin/pricing-simulator">Pricing simulator</NavLink>
            </Menu.Item>
            {userDetails?.role === 'SUPER-ADMIN' && (
              <Menu.Item key="/app/super-admin/change-email">
                <NavLink to="/app/super-admin/change-email">{t('SUPER_ADMIN.TITLES.EMAIL_EDIT')}</NavLink>
              </Menu.Item>
            )}
            <Menu.Item key="/app/super-admin/users-import">
              <NavLink to="/app/super-admin/users-import">{t('SUPER_ADMIN.TITLES.USERS_IMPORT')}</NavLink>
            </Menu.Item>
            <Menu.Item key="/app/super-admin/users-batch">
              <NavLink to="/app/super-admin/users-batch">{t('SUPER_ADMIN.TITLES.USERS_EDIT')}</NavLink>
            </Menu.Item>
            <Menu.Item key="/app/super-admin/create-invoice">
              <NavLink to="/app/super-admin/create-invoice">{t('SUPER_ADMIN.TITLES.CREATE_INVOICE')}</NavLink>
            </Menu.Item>
            <Menu.Item key="/app/super-admin/impersonate">
              <NavLink to="/app/super-admin/impersonate">Impersonate</NavLink>
            </Menu.Item>
            {userDetails?.customLevel === '1001' && (
              <Menu.Item key="/app/super-admin/data-metrics">
                <NavLink to="/app/super-admin/data-metrics">{t('SUPER_ADMIN.TITLES.DATA_METRICS')}</NavLink>
              </Menu.Item>
            )}
            {SUPER_ADMIN_ACCOUNTING_ALLOWED_EMAILS.includes(userDetails!.email) && !impersonate && (
              <Menu.ItemGroup title="Accounting">
                <Menu.Divider />
                <Menu.Item key="/app/super-admin/accounting/customers">
                  <NavLink to="/app/super-admin/accounting/customers">Customers</NavLink>
                </Menu.Item>
                <Menu.Item key="/app/super-admin/accounting/invoices">
                  <NavLink to="/app/super-admin/accounting/invoices">Invoices</NavLink>
                </Menu.Item>
                <Menu.Item key="/app/super-admin/accounting/credit-notes">
                  <NavLink to="/app/super-admin/accounting/credit-notes">Credit notes</NavLink>
                </Menu.Item>
                <Menu.Item key="/app/super-admin/accounting/subscriptions">
                  <NavLink to="/app/super-admin/accounting/subscriptions">Subscriptions</NavLink>
                </Menu.Item>
                <Menu.Item key="/app/super-admin/accounting/quotes">
                  <NavLink to="/app/super-admin/accounting/quotes">Quotes</NavLink>
                </Menu.Item>
              </Menu.ItemGroup>
            )}
          </Menu.SubMenu>
        )}
        <Menu.SubMenu
          title={
            <>
              <i className="icon-help" />
              <span className="submenu-title">{t('MENU.HELP.TITLE')}</span>
            </>
          }
          key={'sub6'}
        >
          <Menu.Item key="sub6-1" onClick={openWhatsNew}>
            &#x1F680; {t('MENU.HELP.WHATS_NEW')}
          </Menu.Item>
          <Menu.Item key="sub6-2" onClick={openSupport}>
            &#x2753; {t('MENU.HELP.SUPPORT')}
          </Menu.Item>
          {activeDepartment?.support && (
            <Menu.Item key="sub6-3" onClick={openChat}>
              &#x1F4AC; {t('MENU.HELP.CHAT')}
            </Menu.Item>
          )}
        </Menu.SubMenu>

        <Menu.SubMenu
          title={
            <>
              <Avatar size={20} style={{ padding: 0, margin: 0 }}>
                {userDetails?.initials}
              </Avatar>
              <span className="submenu-title submenu-title-profile">{t('GLOBAL.MY_PROFILE')}</span>
            </>
          }
          key={'sub7'}
        >
          <Menu.Divider />
          <Menu.SubMenu title={activeDepartment?.company} style={{ paddingLeft: 10 }}>
            {departments.map((department, i) => (
              <Menu.Item key={`sub7-${i + 1}`} onClick={() => changeDepartment(department.id!)}>
                {department.company}
              </Menu.Item>
            ))}
          </Menu.SubMenu>
          <Menu.Item key="/app/settings/bills">
            <NavLink to="/app/settings/bills">{t('SETTINGS.BILLING.TITLE')}</NavLink>
          </Menu.Item>
          <Menu.Item key="/app/logs">
            <NavLink to="/app/logs">{t('LOGS.TITLE')}</NavLink>
          </Menu.Item>
          <Menu.Item key="/app/settings/information/profile">
            <NavLink to="/app/settings/information/profile">{t('SETTINGS.INFORMATION.PROFILE.TITLE')}</NavLink>
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
      <Menu mode="inline">
        <Menu.Item
          key="99"
          style={{ fontSize: 14, paddingLeft: 18, textTransform: 'uppercase', color: colors.green }}
          onClick={logout}
        >
          <i className="icon-logout" />
          <span className="submenu-title">{t('GLOBAL.LOGOUT')}</span>
        </Menu.Item>
      </Menu>
    </Drawer>
  );
};

export default styled(HeaderSmallDrawer)`
  .ant-menu-inline {
    border-right: none;
  }

  .logo-icon {
    width: 22px;
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    gap: 15px;
  }

  .submenu-title {
    margin-left: 15px;
  }

  .submenu-title-profile {
  }
`;
