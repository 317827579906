import TableRowActions from '@/pages/app/reports/attendance-sheets/components/TableRowActions';
import { IDepartment } from '@/types/department.model';
import { IUser } from '@/types/user.model';
import { Table } from 'antd';
import Axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  className?: string;
  user?: IUser;
  department?: IDepartment;
}

const AttendanceSheets: React.FC<Props> = ({ className, user, department }) => {
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });

    setColumns([
      {
        title: t('GLOBAL.MONTH'),
        key: 'month',
        // eslint-disable-next-line react/display-name
        render: (text: string, item: IDataSourceItem) => {
          return <span style={{ textTransform: 'capitalize' }}>{moment.unix(item.start).format('MMMM YYYY')}</span>;
        },
      },
      {
        title: t('GLOBAL.ENTRY_DATE'),
        key: 'start',
        // eslint-disable-next-line react/display-name
        render: (text: string, item: IDataSourceItem) => {
          return moment.unix(item.start).format('L');
        },
      },
      {
        title: t('GLOBAL.RELEASE_DATE'),
        key: 'end',
        // eslint-disable-next-line react/display-name
        render: (text: string, item: IDataSourceItem) => {
          return moment.unix(item.end).format('L');
        },
      },
      {
        title: t('GLOBAL.ACTIONS'),
        key: 'actions',
        // eslint-disable-next-line react/display-name
        render: (text: string, item: IDataSourceItem) => {
          const { start, end } = item;
          return <TableRowActions recordId={user!.recordId!} onDownload={() => onDownload({ start, end })} />;
        },
      },
    ]);
  }, [i18n.language, user]);

  const onDownload = ({ start, end }: IDataSourceItem): Promise<AxiosResponse<any>> => {
    return Axios.get(`${process.env.REACT_APP_API_URL_V4}/reports/attendance-sheets`, {
      params: {
        departmentId: department?.id,
        start: moment.unix(start).format('YYYY-MM-DD'),
        end: moment.unix(end).format('YYYY-MM-DD'),
        recordId: user?.recordId,
      },
    });
  };

  const dataSource = useCallback(() => {
    const dataSource = [];
    const start = moment().startOf('month');
    for (let index = 0; index < 12; index++) {
      start.subtract(1, 'month');
      dataSource.push({
        start: start.unix(),
        end: moment(start).endOf('month').unix(),
      });
    }
    return dataSource;
  }, []);

  return (
    <div className={className}>
      <Table dataSource={dataSource()} columns={columns} rowKey="start" pagination={false} />
    </div>
  );
};

export default AttendanceSheets;

interface IDataSourceItem {
  start: number;
  end: number;
}
