import Constraints from '@/pages/app/components/Constraints';
import AppContext from '@/pages/app/context';
import { PickerType } from '@/pages/app/hours/manage/redux/store';
import Colors from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { IShift } from '@/types/shift.model';
import { IUser } from '@/types/user.model';
import { isFeatureEnabled, isNullOrUndefined } from '@/utils';
import { Modal } from 'antd';
import axios from 'axios';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { Dispatch, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import NoSectionSelected from '../components/NoSectionSelected';
import OpenShiftRow from '../components/OpenShiftRow';
import UserShiftLoadingRow from '../components/UserShiftLoadingRow';
import UserShiftRow from '../components/UserShiftRow';
import { ActionType, copyShifts, updateShiftsAndContextWithShiftResponse } from '../redux/actions';
import { InitialStateType } from '../redux/store';
import ModalAyError from './components/ModalAyError';
import Shifts from './components/Shifts';
import TotalHours from './components/TotalHours';

interface Props {
  className?: string;
  key?: string;
  refTotalHours: React.MutableRefObject<null> | null;
  refPageContainer: React.MutableRefObject<null> | null;
  activeSection: string | undefined;
  department: IDepartment | null;
  turnover: any;
}

export type DailyUserShiftsType = {
  user: IUser;
  days: {
    date: number;
    shifts: IShift[];
  }[];
  totalMinutes: number;
  totalPrice: number;
};

export interface ITotalRole {
  id: string | null;
  name: string;
  color: string | undefined;
  background: string | undefined;
  minutes: number;
  price: number;
}

const AppHoursManageDaily: React.FC<Props> = ({
  className,
  key,
  refTotalHours,
  refPageContainer,
  activeSection,
  department,
  turnover,
}) => {
  const { t } = useTranslation();
  const {
    filteredShiftsByUsers,
    days,
    picker,
    selectedDate,
    showOpenShifts,
    isLoading,
    comparisonTemplate,
    ayErrors,
    clipboardHistory,
    startDate,
    endDate,
    shiftsMap,
    commandSelectedKey,
    commandSelectedElement,
    commandSelectedIndex,
    commandSelectedActivated,
    selectedShifts,
    showShifts,
    showAbsences,
    showOtherDepartments,
    showProductivityIndex,
    filterOptions,
  } = useSelector(
    ({
      filteredShiftsByUsers,
      days,
      picker,
      selectedDate,
      showOpenShifts,
      isLoading,
      comparisonTemplate,
      ayErrors,
      clipboardHistory,
      startDate,
      endDate,
      shiftsMap,
      commandSelectedKey,
      commandSelectedElement,
      commandSelectedIndex,
      commandSelectedActivated,
      selectedShifts,
      showShifts,
      showAbsences,
      showOtherDepartments,
      showProductivityIndex,
      filterOptions,
    }: InitialStateType) => ({
      filteredShiftsByUsers,
      days,
      picker,
      selectedDate,
      showOpenShifts,
      isLoading,
      comparisonTemplate,
      ayErrors,
      clipboardHistory,
      startDate,
      endDate,
      shiftsMap,
      commandSelectedKey,
      commandSelectedElement,
      commandSelectedIndex,
      commandSelectedActivated,
      selectedShifts,
      showShifts,
      showAbsences,
      showOtherDepartments,
      showProductivityIndex,
      filterOptions,
    }),
    shallowEqual,
  );
  const {
    state: { features, activeDepartmentId, activeDepartment, skills, userCategories, users },
  } = useContext(AppContext);

  const hasTotalHours = filteredShiftsByUsers.some(
    (shiftByUser) =>
      shiftByUser.shifts.filter((shift) => !shift.dayoff || (shift.dayoff && shift.dayoff.paid)).length > 0,
  );

  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const routeMatch = useRouteMatch();
  const paths = routeMatch.path.split('/');
  const currentPicker = paths[paths.length - 2] as PickerType;
  const { formatedDate } = routeMatch.params as any;

  useEffect(() => {
    if (picker !== currentPicker || selectedDate.format('YYYY-MM-DD') !== formatedDate) {
      let startDate;
      let endDate;

      switch (currentPicker) {
        case 'week':
          startDate = moment(formatedDate).startOf('isoWeek');
          endDate = moment(formatedDate).endOf('isoWeek');
          break;
        case 'month':
          startDate = moment(formatedDate).startOf('month');
          endDate = moment(formatedDate).endOf('month');
          break;
        default:
          startDate = moment(formatedDate).startOf('isoWeek');
          endDate = moment(formatedDate).endOf('isoWeek');
      }

      hoursDispatch({
        type: 'SET_PICKER_AND_SELECTED_DATE',
        payload: {
          picker: currentPicker,
          selectedDate: moment(formatedDate).valueOf(),
          startDate: startDate.valueOf(),
          endDate: endDate.valueOf(),
        },
      });
    }
    // eslint-disable-next-line
  }, [currentPicker, formatedDate]);

  const [shifts, setShifts] = useState<DailyUserShiftsType[]>([]);
  const [openShifts, setOpenShifts] = useState<DailyUserShiftsType | null>(null);
  const [totalByRole, setTotalByRole] = useState<ITotalRole[][]>([]);
  const [selectedHeadcounts, setSelectedHeadcounts] = useState<number[]>([]);

  let selectedKey = commandSelectedKey;
  let selectedIndex = commandSelectedIndex;
  let selectedElement = commandSelectedElement;
  let shiftIds: any[] = [];
  let activated = commandSelectedActivated;

  const keyDownHandler = (e: any) => {
    // Check if the pressed key is an arrow key
    const isArrowKey = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key);
    const isCtrlPressed = e.ctrlKey || e.metaKey;
    const selectableElements = document.querySelectorAll('[class*="selectable-element-"]');

    if (isArrowKey) {
      if (activated) {
        e.preventDefault();
      }
      const list = document.querySelectorAll('[class*="selectable-element-"]');
      let maxKey = -1;

      if (e.key == 'ArrowLeft' || e.key == 'ArrowRight') {
        hoursDispatch({
          type: 'SET_COMMAND_SELECTED_ACTIVATED',
          payload: true,
        });
        activated = true;
      }

      if (selectableElements.length <= 0) return;
      switch (e.key) {
        case 'ArrowUp':
          if (!activated) break;
          selectedKey--;
          if (selectedIndex == -1) selectedIndex = 0;
          break;
        case 'ArrowLeft':
          hoursDispatch({
            type: 'SET_COMMAND_SELECTED_ACTIVATED',
            payload: true,
          });
          activated = true;
          selectedIndex--;
          break;
        case 'ArrowDown':
          if (!activated) break;
          selectedKey++;
          if (selectedIndex == -1) selectedIndex = 0;
          break;
        case 'ArrowRight':
          hoursDispatch({
            type: 'SET_COMMAND_SELECTED_ACTIVATED',
            payload: true,
          });
          activated = true;
          selectedIndex++;
          break;
        default:
          break;
      }

      if (activated) {
        list.forEach((element) => {
          const classes = element.className.split(' ');
          classes.forEach((className) => {
            if (className.startsWith('selectable-element-')) {
              const indexStr = className.replace('selectable-element-', '');
              const index = parseInt(indexStr, 10);
              if (!isNaN(index) && index > maxKey) {
                maxKey = index;
              }
            }
          });
        });
      }

      if (selectedKey == -1) selectedKey = 0;
      if (selectedIndex == -1) selectedIndex = 0;
      if (selectedKey >= maxKey) selectedKey = maxKey;

      const nodeList = document.querySelectorAll(`[class*="selectable-element-${selectedKey}"]`);
      const elementsInKey = Array.from(nodeList);

      if (selectedIndex >= elementsInKey.length) selectedIndex = elementsInKey.length - 1;

      const elementToSelect = elementsInKey[selectedIndex];
      selectableElements.forEach((element) => {
        element.classList.remove('selectable-selected');
      });
      if (elementToSelect) {
        elementToSelect.classList.add('selectable-selected');
        hoursDispatch({
          type: 'SET_COMMAND_SELECTED_ELEMENT',
          payload: elementToSelect,
        });
        selectedElement = elementToSelect;
      }

      hoursDispatch({
        type: 'SET_COMMAND_SELECTED_KEY',
        payload: selectedKey,
      });
      hoursDispatch({
        type: 'SET_COMMAND_SELECTED_INDEX',
        payload: selectedIndex,
      });

      if (selectedElement) {
        selectedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }

    if (isCtrlPressed && e.key === 'c' && selectedElement) {
      if (selectedKey == 0) {
        const shiftsArray = Array.from(shiftsMap.values());
        const date = days[selectedIndex];
        const allDayShifts = shiftsArray.filter((shift) =>
          moment.unix(shift.start!).isSame(moment.unix(date.date), 'day'),
        );
        shiftIds = allDayShifts.map((shift) => shift.id);
      } else {
        if (selectedElement.children) {
          shiftIds = Array.from(selectedElement.children)
            .map((element) => element.id || null)
            .filter((el) => el != null);
        }
      }
      if (shiftIds.length == 0) return;
      copyShifts(hoursDispatch, {
        ids: shiftIds as string[],
      });
    }

    if (isCtrlPressed && e.key === 'v') {
      const shiftsToCopy: any[] = [];
      if (shiftIds.length == 0 && clipboardHistory?.shifts.length && clipboardHistory.shifts.length > 0) {
        shiftIds = clipboardHistory.shifts.map((shift) => shift.id);
      }
      shiftIds.forEach((id) => {
        if (shiftsMap.get(id)) {
          shiftsToCopy.push(shiftsMap.get(id));
        }
      });
      if (!shiftsToCopy || shiftsToCopy.length == 0 || !selectedElement?.classList) return;
      let timestamp: any;
      let recordId: any;
      if (selectedKey == 0) {
        timestamp = days[selectedIndex].date * 1000;
      } else {
        const classes = Array.from(selectedElement?.classList);
        const date = classes.find((el) => el.startsWith('selectable-date-'));
        const userId = classes.find((el) => el.startsWith('selectable-content-'));
        if (!date || !userId) return;
        timestamp = Number(date.split('-')[2]);
        recordId = userId?.split('-')[2];
      }
      if (!timestamp) return;
      const secondMoment = moment.unix(timestamp / 1000);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v3/shifts`,
          {
            shifts: shiftsToCopy.map((shift) => {
              const startMoment = moment.unix(shift.start!);
              const startHours = startMoment.hours();
              const startMinutes = startMoment.minutes();
              const endMoment = moment.unix(shift.end!);
              const endHours = endMoment.hours();
              const endMinutes = endMoment.minutes();
              return {
                id: shift.id,
                userRecordId: recordId == 'null' ? 0 : recordId || shift.userRecordId,
                start: secondMoment.clone().add(startHours, 'hours').add(startMinutes, 'minutes').unix(),
                end: secondMoment.clone().add(endHours, 'hours').add(endMinutes, 'minutes').unix(),
              };
            }),
          },
          {
            params: {
              picker,
              startDate: startDate.unix(),
              endDate: endDate.unix(),
              departmentId: activeDepartmentId,
              sectionId: activeSection,
              force: true,
            },
          },
        )
        .then((response) => {
          updateShiftsAndContextWithShiftResponse(hoursDispatch, response, {
            department: activeDepartment!,
            picker,
            startDate,
            endDate,
            activeSection,
            skills,
            userStatus: userCategories,
          });
          const { totalErrors = 0 } = response.data.message;
          if (totalErrors) {
            const errors: any[] | null = response.data.message.error;
            if (!errors) return;
            const messages = errors.flatMap((error) => error.message);

            Modal.confirm({
              className: 'modal-constraints',
              icon: null,
              title: t('SCHEDULE.CONSTRAINTS.TITLE'),
              content: (
                <Constraints
                  message={messages || []}
                  recordIds={errors.flatMap((error) => error.recordId)}
                  users={users}
                />
              ),
            });
          }
        });
    }

    if (isCtrlPressed && e.key === 'a') {
      e.preventDefault();
      const allShifts = Array.from(shiftsMap.values()).map((shift) => shift.id!);
      const allselectedShifts = Array.from(selectedShifts.values());
      if (allShifts.length == allselectedShifts.length) {
        hoursDispatch({
          type: 'SET_SELECTED_SHIFTS',
          payload: [],
        });
      } else {
        hoursDispatch({
          type: 'SET_SELECTED_SHIFTS',
          payload: [...allShifts],
        });
      }
    }

    if (isCtrlPressed && (e.key === 'Delete' || e.key === 'Backspace')) {
      const allSelectedShifts = Array.from(selectedShifts.values());
      if (allSelectedShifts.length == 0) return;
      hoursDispatch({
        type: 'SET_SHIFT_IDS_TO_DELETE',
        payload: [...allSelectedShifts],
      });
    }

    if (e.code === 'Space' || e.key === ' ') {
      e.preventDefault();
      if (selectedElement) {
        if (selectedElement.children) {
          const shifts = Array.from(selectedShifts.values());
          shiftIds = Array.from(selectedElement.children)
            .map((element) => element.id || null)
            .filter((el) => el != null);

          if (arrayContainsSubarray(shifts, shiftIds)) {
            hoursDispatch({
              type: 'SET_SELECTED_SHIFTS',
              payload: shifts.filter((shift) => !shiftIds.includes(shift)),
            });
          } else {
            hoursDispatch({
              type: 'SET_SELECTED_SHIFTS',
              payload: [...selectedShifts, ...shiftIds],
            });
          }
        }
      }
      if (selectedKey == 0) {
        const shiftsArray = Array.from(shiftsMap.values());
        const date = days[selectedIndex];
        const allDayShifts = shiftsArray.filter((shift) =>
          moment.unix(shift.start!).isSame(moment.unix(date.date), 'day'),
        );
        hoursDispatch({
          type: 'SET_SELECTED_SHIFTS',
          payload: [...selectedShifts, ...allDayShifts.map((shift) => shift.id!)],
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, [shiftsMap, selectedShifts, users]);

  useEffect(() => {
    const totalByRole: ITotalRole[][] = [];
    const selectedHeadcounts: number[] = [];
    const dailyUsersShifts: DailyUserShiftsType[] = [];
    const dailyOpenShifts: DailyUserShiftsType = {
      user: {
        recordId: null,
        ay_sync: false,
      },
      days: [],
      totalMinutes: 0,
      totalPrice: 0,
    };

    for (let i = 0; i < days.length; i++) {
      const currentDay = moment.unix(days[i].date);
      const workingUser = new Set();

      dailyOpenShifts.days.push({
        date: currentDay.valueOf(),
        shifts: [],
      });

      const totalDayByRole: ITotalRole[] = [];

      // eslint-disable-next-line
      filteredShiftsByUsers.forEach((shiftsByUser) => {
        let totalUserMinute = 0;
        let totalUserPrice = 0;

        let totalUserDayMinute = 0;
        let totalUserDayPrice = 0;

        if (shiftsByUser.user.recordId) {
          // USERS SHIFTS
          let userDailyShifts = dailyUsersShifts.find((x) => x.user?.recordId === shiftsByUser.user.recordId);
          if (!userDailyShifts) {
            const length = dailyUsersShifts.push({
              user: shiftsByUser.user,
              days: [
                {
                  date: currentDay.valueOf(),
                  shifts: [],
                },
              ],
              totalMinutes: 0,
              totalPrice: 0,
            });
            userDailyShifts = dailyUsersShifts[length - 1];
          } else {
            userDailyShifts.days.push({
              date: currentDay.valueOf(),
              shifts: [],
            });
          }

          const shiftsOfDayOfUser: IShift[] = [];
          shiftsByUser.shifts.forEach((shift) => {
            const startShiftMoment = moment.unix(shift.start!);
            const endShiftMoment = moment.unix(shift.end!);

            if (startShiftMoment.isBetween(currentDay, moment(currentDay).add(1, 'day'), undefined, '[)')) {
              let hourlyRate = userDailyShifts?.user.hourlyRate;
              const duration = moment.duration(endShiftMoment.diff(startShiftMoment));
              duration.subtract(shift.pause!.unpaid, 'seconds');
              const durationAsMinutes = duration.asMinutes();

              if (isNullOrUndefined(hourlyRate) && shift.skills && shift.skills.length > 0) {
                const maxSkillPrice = Math.max(...shift.skills.map((skill) => skill.price || 0));
                hourlyRate = maxSkillPrice;
              }

              const shiftPrice = shift.price!;
              if (shift.shyftType !== 3) {
                // shiftPrice = (durationAsMinutes / 60) * (hourlyRate || 0);
                const shiftSectionId = shift?.section?.id;
                if (!activeSection || (activeSection && shiftSectionId === activeSection)) {
                  if (!shift.dayoff) {
                    totalUserDayMinute += durationAsMinutes;
                    workingUser.add(shiftsByUser.user.recordId);
                  }
                  totalUserMinute += durationAsMinutes;
                  if (!shift.dayoff || shift?.dayoff?.paid) {
                    totalUserPrice += shiftPrice;
                    totalUserDayPrice += shiftPrice;
                  }
                }
              }

              shiftsOfDayOfUser.push({
                ...shift,
                price: shiftPrice,
              });
            }
          });

          userDailyShifts.days[i].shifts = shiftsOfDayOfUser;
          userDailyShifts.totalMinutes += totalUserMinute;
          userDailyShifts.totalPrice += totalUserPrice;

          const role = userDailyShifts.user.userType || {
            id: null,
            name: 'Autres',
            color: '#ffffff',
            background: '#666666',
          };
          const totalDayRole = totalDayByRole.find((x) => x.id! === role.id!);

          if (totalDayRole) {
            totalDayRole.minutes += totalUserDayMinute;
            totalDayRole.price += totalUserDayPrice;
          } else {
            totalDayByRole.push({
              id: role.id!,
              name: role.name!,
              color: role.color!,
              background: role.background!,
              minutes: totalUserDayMinute,
              price: totalUserDayPrice,
            });
          }
        } else {
          // OPEN SHIFTS
          shiftsByUser.shifts.forEach((shift) => {
            const startShiftMoment = moment.unix(shift.start!);
            if (startShiftMoment.isBetween(currentDay, moment(currentDay).add(1, 'day'), undefined, '[)')) {
              dailyOpenShifts.days[i].shifts.push(shift);
              if (department?.scheduleParams?.count_free_shift_in_head_count) {
                workingUser.add('free-shifts');
              }
            }
          });
        }
      });

      totalByRole.push(totalDayByRole);
      selectedHeadcounts.push(workingUser.size);
    }
    setTotalByRole(totalByRole);
    setShifts(dailyUsersShifts);
    setOpenShifts(dailyOpenShifts);
    setSelectedHeadcounts(selectedHeadcounts);
    // eslint-disable-next-line
  }, [filteredShiftsByUsers]);

  if (department?.scheduleParams?.force_section && !activeSection) {
    return <NoSectionSelected />;
  }

  if (isLoading) {
    return (
      <React.Fragment>
        {[...new Array(3)].map((loadingRow, loadingRowIndex) => (
          <UserShiftLoadingRow
            key={`userLoading_${loadingRowIndex}`}
            baseClassName={`${className} ${picker}`}
          ></UserShiftLoadingRow>
        ))}
      </React.Fragment>
    );
  }

  const hideTotalHours = !department?.scheduleParams?.sidebarCounters && !department?.scheduleParams?.global_stats;
  const filtering = !!activeSection || showOtherDepartments || !showAbsences || !showShifts || !!filterOptions?.length;

  return (
    <React.Fragment key={key}>
      {isFeatureEnabled(features, FEATURES.FREESHIFTS) && openShifts && (
        <OpenShiftRow baseClassName={`${className} ${picker} no-print`} key={`userShifts_null`}>
          <Shifts userRecordId={null} days={openShifts.days} hideShifts={!showOpenShifts} />
        </OpenShiftRow>
      )}
      {hasTotalHours && (!hideTotalHours || filtering) && (
        <TotalHours
          activeSection={activeSection}
          days={days}
          totalByRole={totalByRole}
          refTotalHours={refTotalHours}
          refPageContainer={refPageContainer}
          currency={department?.currency || ''}
          comparison={!isEmpty(comparisonTemplate)}
          department={department}
          selectedHeadcounts={selectedHeadcounts}
          turnover={turnover}
        />
      )}
      {shifts.map((dailyUserShifts, index) => {
        const { user, totalPrice, days, totalMinutes } = dailyUserShifts;
        const { recordId: userRecordId } = user;
        let start = isFeatureEnabled(features, FEATURES.FREESHIFTS) ? 2 : 1;
        return (
          <UserShiftRow
            key={`userShifts_${userRecordId}`}
            baseClassName={`${className} ${picker}`}
            totalMinutes={totalMinutes}
            totalPrice={totalPrice}
            user={user}
            activeSection={activeSection}
            activeDepartment={department || undefined}
            showSkillInsteadOfStatus={department?.scheduleParams?.orderByWeek === 'SKILL'}
          >
            <Shifts userRecordId={userRecordId!} days={days} rowIndex={index + start} />
          </UserShiftRow>
        );
      })}
      <ModalAyError errors={ayErrors} visible={ayErrors != null} />
    </React.Fragment>
  );
};

const AppHoursManageDailyStyled = styled(AppHoursManageDaily)`
  display: flex;
  position: relative;
  width: 100%;
  min-width: 100%;

  &.month {
    width: fit-content;
  }

  > .left {
    position: sticky;
    z-index: 1;
    left: 0;
    padding: 10px;
    border-right: 1px solid ${Colors.blueLight};
    border-bottom: 1px solid ${Colors.blueLight};
    background: white;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: #f9f9f9;
    @media print {
      width: 120px;
    }
  }

  > .right {
    flex: 1;
    width: auto;

    .selectable-selected {
      border: none !important;
      box-shadow: inset 0 0 0 2px #00a651 !important;
      background-color: #d2f3e1 !important;
      border-radius: 4px;
    }
  }

  @media screen and (max-width: 900px) {
    > .left {
      width: 150px;
      overflow: hidden;
    }
  }
`;

export default AppHoursManageDailyStyled;

const arrayContainsSubarray = (mainArray: string[], subArray: string[]): boolean =>
  mainArray.some((_, index) => subArray.every((subItem, subIndex) => mainArray[index + subIndex] === subItem));
