import AppContext from '@/pages/app/context';
import { FEATURES } from '@/types/features.model';
import { handleError, isFeatureEnabled } from '@/utils';
import { Menu, message, Tooltip } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { InitialStateType } from '../../redux/store';

interface Props {
  className?: string;
  onClick: () => void;
}

const DuplicatePreviousWeek: React.FC<Props> = ({ className, onClick }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, activeSection, users, features },
    dispatch,
  } = useContext(AppContext);
  const { selectedDate } = useSelector(({ startDate, selectedDate, picker, shiftsMap }: InitialStateType) => ({
    startDate,
    selectedDate,
    picker,
    shiftsMap,
  }));

  const duplicateWeek = () => {
    const currentWeekNumber = selectedDate.week();
    const isLastYear = currentWeekNumber == 1;
    const lastYear = selectedDate.endOf('week').year() - 1;
    const lastWeekOfPreviousYear = moment()
      .year(isLastYear ? lastYear : selectedDate.year())
      .month(11)
      .date(28)
      .isoWeek();
    const weeks = [{ week_number: moment(selectedDate).week(), year: moment(selectedDate).endOf('week').year() }];
    localStorage.setItem('schedule.duplications.locked-weeks', JSON.stringify(weeks));
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/shifts/duplicate-week`,
        {
          week_number: isLastYear ? lastWeekOfPreviousYear : currentWeekNumber - 1,
          year: isLastYear ? lastYear : selectedDate.year(),
          users: users
            .filter((user: any) =>
              activeSection && typeof user.sections == 'string'
                ? user.sections.split(',').includes(activeSection)
                : true,
            )
            .map((user) => user.recordId),
          weeks,
          freeshifts: false,
          import_shifts: true,
          import_no_section: !isFeatureEnabled(features, FEATURES.SECTIONS) ? true : !activeSection,
          import_freeshifts: isFeatureEnabled(features, FEATURES.FREESHIFTS),
          import_dayoffs: isFeatureEnabled(features, FEATURES.LEAVE_MANAGEMENT),
          import_unavailabilities: true,
          freeshifts_if_booked: isFeatureEnabled(features, FEATURES.FREESHIFTS) ? true : false,
        },
        {
          params: {
            departmentId: activeDepartmentId,
            sectionId: activeSection,
          },
        },
      )
      .then(({ data }) => {
        message.success(t('SCHEDULE.IMPORT_WEEK.REQUEST_SENT'));
      })
      .catch((err) => {
        handleError(err);
      });
    onClick();
  };

  const savedWeeks = localStorage.getItem('schedule.duplications.locked-weeks');
  const lockedWeeks = savedWeeks ? JSON.parse(savedWeeks) : [];

  if (
    lockedWeeks &&
    lockedWeeks.some(
      (entry: any) => entry.week_number == moment(selectedDate).week() && entry.year == moment(selectedDate).year(),
    )
  ) {
    return (
      <Menu.Item className={className} onClick={duplicateWeek} disabled={true}>
        <Tooltip overlay={t('SCHEDULE.DUPLICATE_PREVIOUS_WEEK_LOCKED_TOOLTIP')}>
          <i className="icon-clone" /> {t('SCHEDULE.DUPLICATE_PREVIOUS_WEEK')}
        </Tooltip>
      </Menu.Item>
    );
  } else {
    return (
      <Menu.Item className={className} onClick={duplicateWeek}>
        <i className="icon-clone" /> {t('SCHEDULE.DUPLICATE_PREVIOUS_WEEK')}
      </Menu.Item>
    );
  }
};

export default styled(DuplicatePreviousWeek)`
  margin: 0 5px;
  &:hover {
    background-color: #efefef !important;
    border-radius: 8px;
  }
`;
